import React, { useState } from "react";
import { Box, Typography, Divider, Tabs, Tab } from "@mui/material";

import NotificationSettings from "./Notifications";
import Preferences from "./Prefernces";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const PreferencesCenter = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { primaryColor, primaryColorDark } = useOrganisation();

  return (
    <section className="sectionCenter">
      <Box p={4}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Preference Center
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          textColor="inherit"
          indicatorColor="primary"
          variant="fullWidth"
          sx={{
            ".MuiTabs-indicator": {
              backgroundColor: primaryColorDark,
            },
          }}
        >
          <Tab
            label="Content Filters"
            sx={{
              fontWeight: activeTab === 0 ? "bold" : "normal",
            }}
          />
          <Tab
            label="Notifications"
            sx={{
              fontWeight: activeTab === 1 ? "bold" : "normal",
            }}
          />
        </Tabs>

        {activeTab === 0 && <Preferences />}

        {activeTab === 1 && <NotificationSettings />}
      </Box>
    </section>
  );
};

export default PreferencesCenter;
