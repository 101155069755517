import React, { useEffect } from "react";
import AccountPage from "../../compnents/pages/Settings/Account";

const AccountSetting = () => {
  useEffect(() => {
    document.title = `Account Settings `;
    window.scrollTo(0, 0);
  });

  return (
    <main id="main" tabIndex={0}>
      <AccountPage />
    </main>
  );
};

export default AccountSetting;
