import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";

export const columns = ({
  setSortBy,
  setIsDescending,
  isDescending,
  handleEdit,
  handleSelectAll,
  selectedIds,
  handleSelect,
  handleStatusChange,
  primaryColor,
}) => [
  {
    accessorKey: "title",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsSomeRowsSelected().length === 10}
          onChange={() => handleSelectAll()}
          style={{ color: "white" }}
        />

        <div
          onClick={() => {
            setSortBy("title");
            setIsDescending(!isDescending);
          }}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body2">title</Typography>
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Checkbox
          checked={selectedIds.includes(row.original.id)}
          onChange={() => handleSelect(row.original.id)}
        />
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.title}
        </Box>
      </>
    ),
  },

  {
    accessorKey: "dateCreated",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("DateCreated");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Date Created</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {dayjs(row.original.dateCreated).format("DD/MM/YYYY")}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "status",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("status");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Status</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Select
          value={row.original.status}
          onChange={(event) =>
            handleStatusChange(row.original.id, event.target.value)
          }
          sx={{ minWidth: 120 }}
          style={{
            border: "2px solid",

            borderColor:
              row.original.status === "Active" ? primaryColor : "red",
            borderRadius: "24px",
          }}
        >
          <MenuItem style={{ backgroundColor: "none" }} value="Deleted">
            Archived
          </MenuItem>
          <MenuItem style={{ backgroundColor: "none" }} value="Active">
            Active
          </MenuItem>
        </Select>
      </>
    ),
  },
  {
    id: "edit",
    header: "",
    cell: ({ row }) => (
      <Button
        variant="raised"
        onClick={() => {
          handleEdit(row.original);
        }}
      >
        Edit
      </Button>
    ),
  },
];
