import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// Define types for the add Type
interface addTopicParams {
  title: string;
}

interface TopicResponse {
  msg: string;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<TopicResponse>}
 */
export const addTopic = async (
  title: addTopicParams
): Promise<TopicResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<TopicResponse> = await axios.post(
      `${URL.MANAGE_TOPICS.BASE}${URL.MANAGE_TOPICS.V1_ADD_TOPIC}`,
      {
        title,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for GET all types for admin

interface GetTopicsResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}
interface getTopicsParams {
  searchTerm: string;
  currentPage: string;
  sortBy: string;
  isDescending: boolean;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetTopicsResponse>}
 */
export const getAllTopic = async ({
  searchTerm,
  currentPage,
  sortBy,
  isDescending,
}: getTopicsParams): Promise<GetTopicsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetTopicsResponse> = await axios.get(
    `${URL.MANAGE_TOPICS.BASE}${URL.MANAGE_TOPICS.V1_GET_ALL_TOPICS}?searchTerm=${searchTerm}&pageNumber=${currentPage}&pageSize=10&sortBy=${sortBy}&isDescending=${isDescending}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define topic for edit type by admin

interface topicUpdateParams {
  title: string;
  id: string;
}

interface topicUpdateResponse {
  msg?: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<topicUpdateResponse>} returns a Promise either an error or the response object
 */
export const editTopic = async ({
  title,
  id,
}: topicUpdateParams): Promise<topicUpdateResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  console.log(id);
  const response: AxiosResponse<topicUpdateResponse> = await axios.put(
    `${URL.MANAGE_TOPICS.BASE}${URL.MANAGE_TOPICS.V1_EDIT_TOPIC}/${id}`,
    { title },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for delete topics

interface deleteTopicParams {
  topicIds: string;
}
interface TopicResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<TopicResponse>}
 */
export const deleteTopic = async (
  topicIds: deleteTopicParams
): Promise<TopicResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<TopicResponse> = await axios.put(
    `${URL.MANAGE_TOPICS.BASE}${URL.MANAGE_TOPICS.V1_DELETE_TOPIC}`,
    topicIds,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for restore topics

interface restoreTopicParams {
  topicIds: string;
}
interface TopicResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<TopicResponse>}
 */
export const restoreTopic = async (
  topicIds: restoreTopicParams
): Promise<TopicResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<TopicResponse> = await axios.put(
    `${URL.MANAGE_TOPICS.BASE}${URL.MANAGE_TOPICS.V1_RESTORE_TOPIC}`,
    topicIds,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
