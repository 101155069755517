import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import ScrollToTopButton from "../../common/GoTop";
import Loading from "../../common/Loading";

import TopContent from "./TopContent";
import PaginatedContent from "./PaginatedContent";
import FilterBar from "./FilterBar";
import ArticleCarouselAds from "./ArticleAds";
import FeaturedContent from "./FeaturedContent";
import SearchedContent from "./SearchPage";

import {
  getAllArticles,
  getSearchContentByUserTemplate,
  getUserSearchedContent,
} from "../../../store/api/content";
import { getOnboardedOrganisation } from "../../../store/api/organisation";

const DasboardPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [vendor, setVendor] = useState([]);
  const [region, setRegion] = useState([]);
  const [topic, setTopic] = useState([]);
  const [type, setType] = useState([]);
  const [usersIds, setUsersIds] = useState([]);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const { data, error, isLoading } = useQuery({
    queryKey: ["contentUser"],
    queryFn: () => getAllArticles({ continuationToken: "" }),
    enabled: !isSearch,
  });
  const {} = useQuery({
    queryKey: ["onboardedOrganisationDashboard"],
    queryFn: () => getOnboardedOrganisation(),
  });

  const { data: searchedContent } = useQuery({
    queryKey: ["searchedContent", search, region, topic, type],
    queryFn: () =>
      getUserSearchedContent({
        searchTerm: search,
        usersIds: null,
        topicIds: topic.length ? topic : null,
        regionIds: region.length ? region : null,
        typeIds: type.length ? type : null,
      }),
    enabled: isSearch || search.length > 2,
  });

  const { data: filters } = useQuery({
    queryKey: ["searchTemplate"],
    queryFn: () => getSearchContentByUserTemplate({}),
  });

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <ScrollToTopButton />
      <FilterBar
        region={region}
        setRegion={setRegion}
        vendor={vendor}
        // vendorList={filters.}
        setVendor={setVendor}
        topicList={filters?.topics}
        topic={topic}
        type={type}
        typeList={filters?.types}
        regionList={filters?.regions}
        setTopic={setTopic}
        setType={setType}
        search={search}
        setIsSearch={setIsSearch}
        setSearch={setSearch}
      />
      {isSearch || search.length > 2 ? (
        <SearchedContent
          searchedContent={searchedContent}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      ) : (
        <>
          <TopContent articles={data?.latestApproved} />
          {data?.latestFeatured?.length ? (
            <FeaturedContent articles={data?.latestFeatured} />
          ) : null}
          {/* <ArticleCarouselAds /> */}
          <PaginatedContent />
        </>
      )}
    </>
  );
};

export default DasboardPage;
