import { jwtDecode } from "jwt-decode";
import { refreshToken } from "../store/api/users";

export const checkTokenExpiration = (token) => {
  if (token) {
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    if (expirationTime > currentTime) {
      return false;
    } else {
      return true;
    }
  }
};

export const checkAuth = async () => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const refreshTokenData = localStorage
    .getItem("refreshToken")
    ?.replace(/^"|"$/g, "");
  if (token) {
    try {
      //! 1 check token expiration
      const isTokenExpired = checkTokenExpiration(token);
      //! 2 if expired get a new token and new refreshtoken
      if (isTokenExpired) {
        const response = await refreshToken({ refreshTokenData });
        localStorage.setItem("token", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
        return true;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error refreshing token", error);
      return false;
    }
  } else {
    return false;
  }
};

export const getRole = () => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  if (token) {
    const decoded = jwtDecode(token);
    const role =
      decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return role;
  }
};
