import { Box, Checkbox, Typography } from "@mui/material";
import { SwapVert as SwapVertIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import Button from "../../common/Button";

export const columns = ({
  setSortBy,
  setIsDescending,
  isDescending,
  handleEditOrganisation,
  handleSelectAll,
  selectedIds,
  handleSelect,
}) => [
  {
    accessorKey: "title",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsSomeRowsSelected().length === 10}
          onChange={() => handleSelectAll()}
          style={{ color: "white" }}
        />
        <Typography variant="body2">Name</Typography>
        <div
          onClick={() => {
            setSortBy("fullName");
            setIsDescending(!isDescending);
          }}
        >
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Checkbox
          checked={selectedIds.includes(row.original.id)}
          onChange={() => handleSelect(row.original.id)}
        />
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.title}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "email",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("email");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Email</Typography>
        <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "location",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("location");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Location</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "phoneNumber",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("location");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Phone Number</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "dateCreated",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => setSortBy("DateCreated")}
      >
        <Typography variant="body2">Date Created</Typography>
        <SwapVertIcon
          onClick={() => {
            setIsDescending(!isDescending);
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {dayjs(row.original.dateCreated).format("DD/MM/YYYY")}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "status",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("userType");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Status</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "isOnBoarded",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("userType");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">is OnBoarded</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.isOnBoarded ? "Yes" : "No"}
        </Box>
      </>
    ),
  },
  {
    id: "view",
    header: "",
    cell: ({ row }) => (
      <Button
        text="Edit"
        onClick={() => {
          handleEditOrganisation(row.original.id);
        }}
      />
    ),
  },
];
