import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkTokenExpiration } from "../helpers/auth";
import { refreshToken } from "../store/api/users";

const checkAuth = async () => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const refreshTokenData = localStorage
    .getItem("refreshToken")
    ?.replace(/^"|"$/g, "");

  if (token) {
    try {
      const isTokenExpired = checkTokenExpiration(token);
      if (isTokenExpired) {
        const response = await refreshToken({ refreshTokenData });
        localStorage.setItem("token", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
      }
      return true;
    } catch (error) {
      console.error("Error refreshing token", error);
      return false;
    }
  } else {
    return false;
  }
};

export const useCheckAuth = () => {
  const location = useLocation();

  // Use a query to check auth status
  const { refetch } = useQuery({
    queryKey: ["checkAuth"],
    queryFn: checkAuth,
    refetchOnWindowFocus: true, // Prevent refetching on window focus
    staleTime: 1800000, // 30 minutes
    cacheTime: 1800000, // 30 minutes
    enabled: false, // Disable automatic refetching on mount
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 1800000); // Refetch every 30 minutes

    // Refetch on path change
    refetch();

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [location.pathname, refetch]); // Run effect on path change

  return { refetch };
};
