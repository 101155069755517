import { createBrowserRouter } from "react-router-dom";
import { getRole } from "../../helpers/auth";
import { roles } from "../../constants/routes";

import Login from "../auth/Login";
import ForgetPassword from "../auth/ForgetPassword";
import Dashboard from "../Dashboard";
import CompanyLogin from "../auth/CompanyLogin";
import ResetPassword from "../auth/ResetPassword";
import Reset from "../../compnents/pages/ForgetPassword/Reset";
import NotFoundPage from "../NotFoundPage";
import CreateContent from "../Admin/AddContent";
import AdminDashboard from "../Admin/AdminDashboard";
import PrivateRoute from "./PrivateRoute";
import AllContent from "../Admin/AllContent";
import AllUsers from "../Admin/Users";
import Config from "../Admin/Settings/Config";
import AccountSetting from "../Settings/Account";
import Preferences from "../Settings/Preferences";
import AllNewsLetter from "../Admin/NewsLetter";
import ArticleDetails from "../Dashboard/ArticleDetails";
import Statistics from "../Admin/Statistics";
import AllOrganisation from "../Admin/AllOrganisation";
import Onboarding from "../auth/Onboarding";
import AuthRedirect from "./AuthRedirect";
import Broadcast from "../Admin/Broadcast";
import AllApprovedContent from "../Admin/ApprovedContent";
import QueuedContent from "../Admin/QueuedContent";

export const routesConfig = [
  //TODO ADD 2 bases : superadmin ,
  {
    path: `/`,
    element: <AuthRedirect element={<CompanyLogin />} />,
  },
  {
    path: `/login/:companyName`,
    element: <AuthRedirect element={<Login />} />,
  },
  {
    path: `/workspace`,
    element: <Login superadmin={true} />,
  },
  {
    path: `/reset-password`,
    element: <ResetPassword />,
  },

  { path: `/reset`, element: <Reset /> },

  {
    path: `/forgetPassword`,
    element: <ForgetPassword />,
  },
  //Private
  {
    path: `/onboarding`,
    element: <Onboarding />,
    requiredRoles: [roles.Admin],
  },
  {
    path: `/dashboard`,
    element: <Dashboard />,
    requiredRoles: [
      roles.Reader,
      roles.Editor,
      roles.Admin,
      roles.ContentCreator,
    ],
  },

  {
    path: "/article/details/:id",
    element: <ArticleDetails />,
    requiredRoles: [
      roles.Reader,
      roles.Editor,
      roles.Admin,
      roles.ContentCreator,
    ],
  },

  {
    path: "/settings/preferences",
    element: <Preferences />,
    requiredRoles: [
      roles.Reader,
      roles.Editor,
      roles.Admin,
      roles.ContentCreator,
    ],
  },
  {
    path: "/settings/account",
    element: <AccountSetting />,
    requiredRoles: [
      roles.Reader,
      roles.Editor,
      roles.Admin,
      roles.ContentCreator,
    ],
  },
  {
    path: `/admin-dashboard`,
    element: <AdminDashboard />,
    requiredRoles: [
      roles.Editor,
      roles.Admin,
      roles.SuperAdmin,
      roles.ContentCreator,
    ],
  },
  {
    path: `/create-content`,
    element: <CreateContent />,
    requiredRoles: [roles.Editor, roles.Admin, roles.ContentCreator],
  },
  {
    path: `/edit-content/:contentId`,
    element: <CreateContent />,
    requiredRoles: [roles.Editor, roles.Admin, roles.ContentCreator],
  },
  {
    path: `/all-content`,
    element: <AllContent />,
    requiredRoles: [roles.Editor, roles.Admin, roles.ContentCreator],
  },
  {
    path: `/all-content/approve`,
    element: <AllApprovedContent />,
    requiredRoles: [roles.Admin, roles.Editor],
  },
  {
    path: `/all-content/schedule`,
    element: <QueuedContent />,
    requiredRoles: [roles.Admin, roles.Editor],
  },

  {
    path: `/all-newsletter`,
    element: <AllNewsLetter />,
    requiredRoles: [roles.Editor, roles.Admin],
  },
  {
    path: `/updateOnboarding`,
    element: <Onboarding update={true} />,
    requiredRoles: [roles.Editor, roles.Admin],
  },

  {
    path: `/broadcast`,
    element: <Broadcast />,
    requiredRoles: [roles.Editor, roles.Admin],
  },
  {
    path: `/all-users`,
    element: <AllUsers />,
    requiredRoles: [roles.Admin, roles.SuperAdmin],
  },
  {
    path: `/all-organisation`,
    element: <AllOrganisation />,
    requiredRoles: [roles.SuperAdmin],
  },
  {
    path: `/statistics`,
    element: <Statistics />,
    requiredRoles: [roles.Admin, roles.SuperAdmin],
  },
  {
    path: "/settings/config",
    element: <Config />,
    requiredRoles: [roles.Editor, roles.Admin, roles.SuperAdmin],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

const createRoleBasedRouter = (userRole) => {
  const filteredRoutes = routesConfig.map((route) => ({
    ...route,
    element: route.requiredRoles ? (
      <PrivateRoute
        element={route.element}
        requiredRoles={route.requiredRoles}
        userRole={userRole}
      />
    ) : (
      route.element
    ),
  }));

  return createBrowserRouter(filteredRoutes);
};

export const router = () => {
  let role = getRole();
  return createRoleBasedRouter(role);
};
