import { Box, Grid, Typography } from "@mui/material";
import Button from "../../../common/Button";
import Input from "../../../common/Input";
import { useEffect, useState } from "react";

const ContentURL = ({
  setStep,
  url,
  setURL,
  SubmitForm,
  isPending,
  setImageError,
  setTitleError,
  setSummaryError,
}) => {
  const [errors, setErrors] = useState({
    url: false,
  });
  useEffect(() => {
    setImageError("");
    setTitleError("");
    setSummaryError("");
  }, []);

  const handlePullURL = () => {
    if (url) {
      SubmitForm();
    } else {
      setErrors({ ...errors, url: true });
    }
  };
  return (
    <Box className="typeContainer">
      <Typography variant="body1">
        Enter the URL of the content that you want to share
      </Typography>
      <Box>
        <Input
          id="urlContent"
          label=""
          name=""
          onChange={setURL}
          required={true}
          type=""
          variant="outlined"
          value={url}
          error={errors.url}
          helperText={errors.url ? "URL is required" : ""}
        />
      </Box>
      <Grid container flex={1} style={{ justifyContent: "center" }}>
        <Grid item xs={4}>
          <Box>
            <Button
              backgroundColor="white"
              text="Go back"
              onClick={() => setStep(0)}
            ></Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={isPending}
            text={isPending ? "Loading" : "Pull Content"}
            onClick={handlePullURL}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentURL;
