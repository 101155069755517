import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// Define types for the add Type
interface addRegionParams {
  title: string;
}

interface RegionResponse {
  msg: string;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<RegionResponse>}
 */
export const addRegion = async (
  title: addRegionParams
): Promise<RegionResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<RegionResponse> = await axios.post(
      `${URL.MANAGE_REGIONS.BASE}${URL.MANAGE_REGIONS.V1_ADD_REGION}`,
      {
        title,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for GET all types for admin

interface GetRegionsResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}
interface getRegionsParams {
  searchTerm: string;
  currentPage: string;
  sortBy: string;
  isDescending: boolean;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetRegionsResponse>}
 */
export const getAllRegions = async ({
  searchTerm,
  currentPage,
  sortBy,
  isDescending,
}: getRegionsParams): Promise<GetRegionsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetRegionsResponse> = await axios.get(
    `${URL.MANAGE_REGIONS.BASE}${URL.MANAGE_REGIONS.V1_GET_ALL_REGIONS}?searchTerm=${searchTerm}&pageNumber=${currentPage}&pageSize=10&sortBy=${sortBy}&isDescending=${isDescending}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define region for edit type by admin

interface regionUpdateParams {
  title: string;
  id: string;
}

interface regionUpdateResponse {
  msg?: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<regionUpdateResponse>} returns a Promise either an error or the response object
 */
export const editRegion = async ({
  title,
  id,
}: regionUpdateParams): Promise<regionUpdateResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<regionUpdateResponse> = await axios.put(
    `${URL.MANAGE_REGIONS.BASE}${URL.MANAGE_REGIONS.V1_EDIT_REGION}/${id}`,
    { title },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for delete regions

interface deleteRegionParams {
  regionIds: string;
}
interface RegionResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<RegionResponse>}
 */
export const deleteRegion = async (
  regionIds: deleteRegionParams
): Promise<RegionResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<RegionResponse> = await axios.put(
    `${URL.MANAGE_REGIONS.BASE}${URL.MANAGE_REGIONS.V1_DELETE_REGION}`,
    regionIds,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for restore regions

interface restoreRegionParams {
  regionIds: string;
}
interface RegionResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<RegionResponse>}
 */
export const restoreRegion = async (
  regionIds: restoreRegionParams
): Promise<RegionResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<RegionResponse> = await axios.put(
    `${URL.MANAGE_REGIONS.BASE}${URL.MANAGE_REGIONS.V1_RESTORE_REGION}`,
    regionIds,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
