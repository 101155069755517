import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Bar } from "react-chartjs-2";
import { PieChart } from "@mui/x-charts/PieChart";
import "chart.js/auto";
import { Grid, Paper, Typography } from "@mui/material";

import DataCard from "../DataCard";
import Loading from "../../../common/Loading";
import Button from "../../../common/Button";
import Table from "../../../common/Table";
import { columns } from "./columns";

import { exportToCSV } from "../../../../helpers";
import {
  getAllCreatedContentStatistics,
  getAllSharedContentStatistics,
  getAllViewedContentStatistics,
  getContentStatistics,
} from "../../../../store/api/statistics";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const ContentTab = ({ tabIndex, index, startDate, endDate, organisation }) => {
  const { primaryColor } = useOrganisation();

  const [selectedCard, setSelectedCard] = useState(null);
  const [statisticsData, setStatisticsData] = useState([
    [
      {
        title: "Content Created",
        count: "0",
      },
      {
        title: "Content Views",
        count: "0",
      },
      {
        title: "Content Shares",
        count: "0",
      },
      {
        title: "Avg Read Time",
        count: "0",
      },
    ],
  ]);

  const handleContentKey = useCallback((selected) => {
    switch (selected) {
      case "created":
        setSelectedCard({
          key: "created",
          getDataFunction: getAllCreatedContentStatistics,
        });
        break;
      case "views":
        setSelectedCard({
          key: "views",
          getDataFunction: getAllViewedContentStatistics,
        });
        break;
      case "shared":
        setSelectedCard({
          key: "shared",
          getDataFunction: getAllSharedContentStatistics,
        });
        break;

      default:
        setSelectedCard(null);
        break;
    }
  }, []);

  const { data: statistics, isLoading } = useQuery({
    queryKey: ["contentStatistics", startDate, endDate, tabIndex, organisation],
    queryFn: () =>
      getContentStatistics({
        startDate,
        endDate,
        organisationId: organisation,
      }),
  });

  const { data: contentStatistics, isLoading: loadContent } = useQuery({
    queryKey: [selectedCard?.key, startDate, endDate],
    queryFn: () =>
      selectedCard.getDataFunction({ startDate, endDate, pageNumber: 1 }),
    enabled: !!selectedCard,
  });

  useEffect(() => {
    if (statistics) {
      setStatisticsData([
        {
          title: "Content Created",
          count: statistics?.created,
          handleClick: () => handleContentKey("created"),
        },
        {
          title: "Content Views",
          count: statistics?.views,
          handleClick: () => handleContentKey("views"),
        },
        {
          title: "Content Shares",
          count: statistics?.shares,
          handleClick: () => handleContentKey("shared"),
        },
        {
          title: "Avg Read Time",
          count: `${statistics?.avgReadTime} sec`,
        },
      ]);
    }
  }, [isLoading, statistics]);

  const mostViewedByTopicData = {
    labels: statistics?.mvByTopic.map((el) => el.name) || [],
    datasets: [
      {
        label: "Most Viewed by Topic",
        data: statistics?.mvByTopic.map((el) => el.count) || [],
        backgroundColor: "#A78BFA",
      },
    ],
  };
  const cloudSecurityData = {
    labels: statistics?.mvByType.map((el) => el.name) || [],
    datasets: [
      {
        label: "Most Viewed by Type",
        data: statistics?.mvByType.map((el) => el.count) || [],
        backgroundColor: primaryColor,
      },
    ],
  };

  if (isLoading && statistics) {
    return <Loading />;
  }
  return (
    <div>
      {/* Statistics Row */}
      <Grid container spacing={2} alignItems="stretch">
        {statisticsData.map((el, i) => (
          <Grid item xs={6} sm={3} key={i}>
            <DataCard
              title={el.title}
              count={el.count}
              onClick={el.handleClick}
            />
          </Grid>
        ))}
      </Grid>

      {/* Charts Row */}
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>
            <Typography variant="h6">Most Viewed Articles</Typography>
            <div style={{ height: "250px" }}>
              {/* <Pie data={pieData} /> */}
              <PieChart
                series={[
                  {
                    data:
                      statistics?.mvArticles.map((article) => ({
                        id: article.name,
                        value: article.count,
                        // Truncate the label if it's too long
                        label: article.name,
                      })) || [],
                    label: { position: "outside", style: { fontSize: 12 } }, // Customize label style here
                  },
                ]}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>
            <Typography variant="h6">Most Viewed by Topic</Typography>
            <div style={{ height: "250px" }}>
              <Bar data={mostViewedByTopicData} />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>
            <Typography variant="h6">Most Viewed by Type</Typography>
            <div style={{ height: "250px" }}>
              <Bar data={cloudSecurityData} />
            </div>
          </Paper>
        </Grid>
      </Grid>
      {/* Table and Export Button */}
      <Grid
        style={{ marginTop: "1em", display: "flex", justifyContent: "end" }}
      >
        <Button
          text="Export CSV"
          onClick={() => exportToCSV(contentStatistics?.paginatedContent)}
        />
      </Grid>
      {loadContent && selectedCard ? (
        <Loading />
      ) : (
        <Table
          data={contentStatistics?.paginatedContent}
          columns={columns({})}
        />
      )}
    </div>
  );
};

export default ContentTab;
