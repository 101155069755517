import React from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import CustomButton from "../../common/Button";
import { Info } from "@mui/icons-material";
import { useOrganisation } from "../../../hooks/useOrganisation";
import CreateImage from "../AddContent/ContentForm/DragImage";

const UploadLogo = ({ logo, handleLogoUpload, handleBack, handleNext }) => {
  const { primaryColor } = useOrganisation();

  // const handleImageUpload = (event) => {
  //   if (event.target?.files?.length > 0) {
  //     const file = event.target.files[0];

  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       handleLogoUpload({
  //         fileName: file.name,
  //         contentType: file.type,
  //         fileData: reader.result.split(",")[1], // Extract the base64 part of the result
  //       });
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     console.error("No file selected or input is undefined.");
  //   }
  // };

  return (
    <Box>
      <Typography variant="h6">
        Upload your logo
        <Tooltip title="Upload an svg OR png logo for a better quality">
          <IconButton>
            <Info />
          </IconButton>
        </Tooltip>
      </Typography>
      <Grid item>
        <CreateImage image={logo} setImage={handleLogoUpload} />
        {/* <Grid container spacing={2} display={"flex"} alignItems={"center"}>
          <Grid item xs={6}>
            {logo ? (
              <img
                src={`data:${logo.contentType};base64,${logo.fileData}`}
                alt="Preview"
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <Box
                sx={{
                  border: "1px dashed grey",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "150px",
                  cursor: "pointer",
                }}
              >
                <Typography>Preview</Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              component="label"
              sx={{
                width: "100%",
                height: "150px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                color: primaryColor,
                border: `1px solid ${primaryColor}`,
              }}
            >
              Drag and drop or click to browse your desktop
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleImageUpload} // Event handler for file upload
              />
              <Typography variant="caption">
                File types: .jpg, .gif, .png or .svg
              </Typography>
            </Button>
          </Grid>
        </Grid> */}
      </Grid>
      <Box sx={{ marginTop: 4 }} display={"flex"} justifyContent={"end"}>
        <CustomButton
          onClick={handleBack}
          sx={{
            marginRight: 2,
          }}
          backgroundColor="white"
          text="Back"
        />

        <CustomButton
          onClick={logo && handleNext}
          text="Next"
          disabled={!logo}
        />
      </Box>
    </Box>
  );
};

export default UploadLogo;
