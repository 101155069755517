import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// -----------------------------------------------------
// Define types for GET all user statistics for admin

interface GetUserStatiscticsResponse {
  subscribers: number;
  unsubscribers: number;
  logins: number;
  active: number;
  inactive: number;
  bounces: number;
  engagements: [];
}

interface getUserStatisticsParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method POST
 * @route
 * @returns {Promise<GetUserStatiscticsResponse>}
 */
export const getUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getUserStatisticsParams): Promise<GetUserStatiscticsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserStatiscticsResponse> = await axios.post(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_USER_STATISTICS}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user login statistics for admin

interface GetUserLoginResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserLoginParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserLoginResponse>}
 */
export const getAllLoginUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getUserLoginParams): Promise<GetUserLoginResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserLoginResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_USER_LOGINS}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */
export const getAllActiveUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_USER_STATISTICS_ACTIVE}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */
export const getAllUnActiveUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_USER_STATISTICS_UNACTIVE}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */
export const getAllSubscriptionUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_USER_STATISTICS_SUBSCRIPTION}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */
export const getAllUnSubscriptionUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_USER_STATISTICS_UNSUBSCRIPTION}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user active statistics for admin

interface GetUserActiveResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getUserActiveParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetUserActiveResponse>}
 */
export const getAllBouncesUserStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getUserActiveParams): Promise<GetUserActiveResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetUserActiveResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_USER_STATISTICS_BOUNCES}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all user statistics for admin

interface GetContentStatiscticsResponse {
  created: number;
  views: number;
  shares: number;
  avgReadTime: number;
  mvArticles: [];
  mvByTopic: [];
  mvByType: [];
}

interface getContentStatisticsParams {
  startDate: string;
  endDate: string;
  organisationId: string;
}
/**
 * @method POST
 * @route
 * @returns {Promise<GetContentStatiscticsResponse>}
 */
export const getContentStatistics = async ({
  startDate,
  endDate,
  organisationId,
}: getContentStatisticsParams): Promise<GetContentStatiscticsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetContentStatiscticsResponse> =
    await axios.post(
      `${URL.STATISTICS.BASE}${
        URL.STATISTICS.V1_ADMIN_CONTENT_STATISTICS
      }?organisationId=${organisationId ? organisationId : ""}`,
      { startDate, endDate },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all content created statistics for admin

interface GetCreatedContentResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getCreatedContentParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetCreatedContentResponse>}
 */
export const getAllCreatedContentStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getCreatedContentParams): Promise<GetCreatedContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetCreatedContentResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_CONTENT_STATISTICS_CREATED}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all content shared statistics for admin

interface GetSharedContentResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getSharedContentParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetSharedContentResponse>}
 */
export const getAllSharedContentStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getSharedContentParams): Promise<GetSharedContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetSharedContentResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_CONTENT_STATISTICS_SHARED}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for GET all viewed content statistics for admin

interface GetViewedContentResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

interface getViewedContentParams {
  startDate: string;
  endDate: string;
  pageNumber: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetViewedContentResponse>}
 */
export const getAllViewedContentStatistics = async ({
  startDate,
  endDate,
  pageNumber,
}: getViewedContentParams): Promise<GetViewedContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetViewedContentResponse> = await axios.get(
    `${URL.STATISTICS.BASE}${URL.STATISTICS.V1_ADMIN_CONTENT_STATISTICS_VIEWS}?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=16`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET all time Notification statistics for admin

interface GetNotificationStatisticsResponse {
  emails: number;
  pushs: number;
  sms: number;
  emailClickRate: number;
  smsClickRate: number;
  pushClickRate: number;
}

interface getNotificationStatisticsParams {
  startDate: string;
  endDate: string;
  organisationId: string;
}
/**
 * @method POST
 * @route
 * @returns {Promise<GetNotificationStatisticsResponse>}
 */
export const getAllNotificationStatistics = async ({
  startDate,
  endDate,
  organisationId,
}: getNotificationStatisticsParams): Promise<GetNotificationStatisticsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetNotificationStatisticsResponse> =
    await axios.post(
      `${URL.STATISTICS.BASE}${
        URL.STATISTICS.V1_ADMIN_NOTIFICATIONS_STATISTICS
      }?organisationId=${organisationId ? organisationId : ""}`,
      { startDate, endDate },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};
