import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import Pagination from "./Pagination";
import { useNavigate } from "react-router-dom";

const ListTable = ({
  data = [],
  columns,
  isLoading,
  totalPage,
  setCurrentPage,
  currentPage,
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 2 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "black", color: "white" }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    sx={{ color: "white", borderColor: "black" }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {isLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableBody>
              {table?.getRowModel().rows.map((row, i) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: i % 2 === 0 ? "#f5f5f5" : "white",
                  }}
                >
                  {row?.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell?.column?.columnDef?.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        xs={12}
        p={2}
      >
        <Grid xs={12} md={6} sm={6} item>
          <Pagination
            count={totalPage}
            color={"primary"}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Grid>
        <Grid item>
          <Button
            variant="raised"
            onClick={() => navigate("/admin-dashboard")}
            style={{ marginRight: 8 }}
          >
            Back to dashboard
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListTable;
