import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";

import { formatDistanceToNow } from "date-fns";
import { useOrganisation } from "../../../hooks/useOrganisation";

const ArticleCard = ({ article, isMobile, isTablet, width, featured }) => {
  const navigate = useNavigate();
  const { primaryColor } = useOrganisation();

  const {
    title = "",
    imageLink = "",
    scheduledDate = "",
    topics = "",
    id = "",
  } = article;
  return (
    <Grid
      item
      xs={12}
      sm={isMobile ? 12 : isTablet ? 6 : 3}
      md={isTablet ? 6 : 3}
      lg={2}
      key={article.id}
    >
      <Card
        sx={{
          maxWidth: isMobile ? "100%" : isTablet ? 280 : 345,
          margin: 1,
          width: width,
          borderRadius: 0,
        }}
        style={
          featured
            ? {
                backgroundColor: "#000",
                color: "#fff",
                width: "280px",
                flex: "0 0 auto",
                boxShadow: "none",
              }
            : {
                boxShadow: "none",
              }
        }
        onClick={() => {
          navigate(`/article/details/${id}`);
        }}
      >
        <CardMedia
          component="img"
          height={isMobile ? "100" : isTablet ? "120" : "140"}
          image={`${imageLink}?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
          alt={title}
        />
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "200px",
          }}
        >
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ color: primaryColor }}
          >
            {topics.split(",").slice(0, 3).join(", ")}
            {/* {topic} */}
          </Typography>
          <Typography
            variant="h6"
            style={{
              wordWrap: "break-word",
              fontSize: 14,
              // overflow: "hidden",
              textOverflow: "ellipsis",
              marginBottom: "auto", // Allow title to grow but push the line down
              fontWeight: "bolder",
            }}
          >
            {title}
          </Typography>
          <hr
            style={{
              borderTop: "1px solid #fff",
              width: "100%",
              margin: 0,
            }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              color: featured ? "white" : "black",
            }}
          >
            {formatDistanceToNow(scheduledDate, {
              addSuffix: true,
            })}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ArticleCard;
