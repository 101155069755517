import React from "react";
import {
  Box,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  OutlinedInput,
  Chip,
  FormControl,
  InputLabel,
  Grid,
  Container,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useOrganisation } from "../../../hooks/useOrganisation";
import Button from "../../common/Button";
import { getTextColor } from "../../../helpers";

const FilterBar = ({
  region,
  setRegion,
  vendor,
  setVendor,
  topic,
  setTopic,
  search,
  setSearch,
  setIsSearch,
  topicList,
  regionList,
  typeList,
  type,
  setType,
}) => {
  const { primaryColor } = useOrganisation();
  const textColor = getTextColor(primaryColor) || "black";

  const handleSelectChange = (event, setter, list) => {
    const { value } = event.target;

    if (value.includes("select-all")) {
      setter(list.map((el) => el.id));
      setIsSearch(true);
      return;
    }

    if (value.includes("unselect-all")) {
      setter([]);
      return;
    }
    setIsSearch(true);
    setter(value);
  };

  const handleClearAll = () => {
    setVendor([]);
    setRegion([]);
    setTopic([]);
    setSearch("");
    setIsSearch(false);
  };

  const renderSelectValue = (selected, list) => {
    if (selected?.length === 0) {
      return null; // Placeholder text
    }
    return (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: 0.5 }}>
        {selected?.slice(0, 2).map((value) => {
          const item = list.find((el) => el.id === value);
          return (
            <Chip
              key={value}
              label={item?.title || value}
              sx={{
                height: "24px",
                background: primaryColor,
                color: textColor,
              }}
            />
          );
        })}
        {selected?.length > 2 && <span style={{ color: "#aaa" }}>...</span>}
      </Box>
    );
  };

  return (
    <Container maxWidth="lg" className="sectionCenter">
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sm={12}
        md={12}
      >
        <Grid item>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              padding: "20px",
              flexWrap: "wrap",
            }}
          >
            {/* Region Select */}
            <FormControl sx={{ minWidth: 180, maxWidth: 180 }}>
              <InputLabel>Region</InputLabel>
              <Select
                label="Region"
                multiple
                value={region}
                onChange={(e) => handleSelectChange(e, setRegion, regionList)}
                input={<OutlinedInput />}
                renderValue={(selected) =>
                  renderSelectValue(selected, regionList)
                }
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value="select-all">Select All</MenuItem>
                <MenuItem value="unselect-all">Unselect All</MenuItem>
                {regionList?.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Topic Select */}
            <FormControl sx={{ minWidth: 180, maxWidth: 300 }}>
              <InputLabel>Topic</InputLabel>
              <Select
                label="Topic"
                multiple
                value={topic}
                onChange={(e) => handleSelectChange(e, setTopic, topicList)}
                input={<OutlinedInput />}
                renderValue={(selected) =>
                  renderSelectValue(selected, topicList)
                }
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value="select-all">Select All</MenuItem>
                <MenuItem value="unselect-all">Unselect All</MenuItem>
                {topicList?.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Type Select */}
            <FormControl sx={{ minWidth: 180, maxWidth: 180 }}>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                multiple
                value={type}
                onChange={(e) => handleSelectChange(e, setType, typeList)}
                input={<OutlinedInput />}
                renderValue={(selected) =>
                  renderSelectValue(selected, typeList)
                }
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
              >
                <MenuItem value="select-all">Select All</MenuItem>
                <MenuItem value="unselect-all">Unselect All</MenuItem>
                {typeList?.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {/* Search Field */}
            <TextField
              variant="standard"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setIsSearch(true);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                disableUnderline: false,
              }}
              sx={{
                minWidth: 180,
                maxWidth: 180,
                "& .MuiInput-underline:before": {
                  borderBottom: "1px solid #ccc",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: `2px solid ${primaryColor}`,
                },
                "& .MuiInput-underline:after": {
                  borderBottom: `2px solid ${primaryColor}`,
                },
              }}
            />

            {/* Clear All Button */}
            <Button
              color="primary"
              onClick={handleClearAll}
              style={{
                textTransform: "none",
                minWidth: 100,
                borderColor: primaryColor,
              }}
              text="Clear All"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FilterBar;
