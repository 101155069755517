import React from "react";
import {
  Button,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

const UserList = ({
  csvData,
  handleSaveRow,
  editingRow,
  setEditingRow,
  handleEditRow,
  handleBackStep,
  handleSubmit,
  selectedTypeTitle,
}) => {
  return (
    <div>
      <h2>Review New Users</h2>
      <Typography variant="body2">Add to {selectedTypeTitle} list</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Full Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {csvData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                {editingRow === index ? (
                  <TextField
                    value={row.fullName}
                    onChange={(e) =>
                      handleSaveRow(index, "fullName", e.target.value)
                    }
                  />
                ) : (
                  row.fullName
                )}
              </TableCell>
              <TableCell>
                {editingRow === index ? (
                  <TextField
                    value={row.email}
                    onChange={(e) =>
                      handleSaveRow(index, "email", e.target.value)
                    }
                  />
                ) : (
                  row.email
                )}
              </TableCell>
              <TableCell>
                {editingRow === index ? (
                  <TextField
                    value={row.location}
                    onChange={(e) =>
                      handleSaveRow(index, "location", e.target.value)
                    }
                  />
                ) : (
                  row.location
                )}
              </TableCell>
              <TableCell>
                {editingRow === index ? (
                  <IconButton onClick={() => setEditingRow(null)}>
                    <CheckIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleEditRow(index)}>
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{
            marginBottom: 8,
            background: "black",
            color: "white",
            cursor: "pointer",
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          onClick={handleBackStep}
          style={{ color: "black", border: "1px solid black" }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default UserList;
