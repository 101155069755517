import { Modal, Box, Typography, Button, TextField } from "@mui/material";

const StatusModal = ({
  open,
  handleClose,
  status,
  handleApproveORReject,
  setMessage,
  message,
  error,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": { backgroundColor: "transparent", opacity: 0.5 },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 12,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Are you sure you want to {status.toLowerCase()} this content?
        </Typography>
        {status === "Rejected" && (
          <>
            <TextField
              label="What is your reason for rejecting?"
              fullWidth
              margin="normal"
              required={true}
              value={message}
              multiline
              rows={4}
              variant="outlined"
              sx={{
                mb: 2,
              }}
              onChange={(e) => {
                e.preventDefault();
                setMessage(e.target.value);
              }}
            />
            {error.message && (
              <p style={{ color: "red", fontSize: 12, marginTop: 0 }}>
                Message is required
              </p>
            )}
          </>
        )}
        <Button
          variant="contained"
          color={status === "Rejected" ? "error" : "primary"}
          onClick={handleApproveORReject}
          fullWidth
          sx={{
            backgroundColor: status === "Rejected" ? "#000" : "#333",
            color: "#fff",
            mb: 2,
            "&:hover": {
              backgroundColor: status === "Rejected" ? "#333" : "#555",
            },
          }}
        >
          {status === "Rejected" ? "Reject content" : "Approve content"}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          fullWidth
          sx={{
            color: "#333",
            borderColor: "#333",
            "&:hover": {
              backgroundColor: "#f0f0f0",
              borderColor: "#333",
            },
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};
export default StatusModal;
