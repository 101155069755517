import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMutation } from "@tanstack/react-query";
import { Box, TextField, Typography, Paper } from "@mui/material";
import { addBroadcastContent } from "../../../store/api/content";
import Button from "../../common/Button";

const SendCustomEmail = () => {
  const [subject, setSubject] = useState("");
  const [contentHtmlSubject, setContentHtmlsubject] = useState("");
  const [contentTextSubject, setContentTextsubject] = useState("");
  const [errors, setErrors] = useState({ subject: false, content: false });

  const { mutate, isLoading } = useMutation({
    mutationFn: addBroadcastContent,
    onSuccess: () => {
      alert("Broadcast content sent successfully!");
    },
    onError: (error) => {
      console.error(error);
      alert("Failed to send broadcast content");
    },
  });
  const handleSend = () => {
    if (!subject || !contentHtmlSubject) {
      setErrors({
        subject: !subject,
        content: !contentHtmlSubject,
      });
      return;
    }
    // Extract plain text content for `contentTextsubject`
    const plainText = contentHtmlSubject.replace(/<[^>]*>/g, ""); // Remove HTML tags
    setContentTextsubject(plainText);

    // Data to be sent to the API
    const data = {
      subject,
      contentText: plainText,
      contentHtml: contentHtmlSubject,
    };

    mutate(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 2,
      }}
    >
      <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
        Send Custom Email
      </Typography>

      <Box
        component={Paper}
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: "600px",
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Subject"
          variant="outlined"
          fullWidth
          required
          error={errors.subject}
          helperText={errors.subject ? "Subject is required" : ""}
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
            setErrors({ ...errors, subject: false });
          }}
        />

        <ReactQuill
          theme="snow"
          value={contentHtmlSubject}
          onChange={(value) => {
            setContentHtmlsubject(value);
            setErrors({ ...errors, content: false });
          }}
          placeholder="Write your email content here..."
          style={{ height: "200px" }}
        />

        {errors.content && (
          <Typography color="error">Content is required</Typography>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            mt: 2,
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <Button
            text={isLoading ? "Sending..." : "Send ALL"}
            style={{}}
            onClick={() => handleSend()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SendCustomEmail;
