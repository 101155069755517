import { Snackbar, Alert } from "@mui/material";

// Function to export table data as CSV
export const exportToCSV = (data) => {
  if (!data || data.length === 0) {
    alert("No data available for export.");
    return;
  }

  const csvHeaders = Object.keys(data[0]);
  const csvRows = data.map((row) =>
    csvHeaders
      .map((header) => JSON.stringify(row[header], (key, value) => value ?? ""))
      .join(",")
  );

  const csvContent = [csvHeaders.join(","), ...csvRows].join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "user_statistics.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Determines if a color is dark based on its RGB luminance.
 * @param {string} color Hex color string (e.g., '#RRGGBB').
 * @returns {boolean} True if the color is dark, false otherwise.
 */
const isDarkColor = (color) => {
  // Convert hex color to RGB
  const r = parseInt(color?.substring(1, 3), 16);
  const g = parseInt(color?.substring(3, 5), 16);
  const b = parseInt(color?.substring(5, 7), 16);

  // Calculate relative luminance (ITU-R BT.709 formula)
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return true if luminance is less than 128 (out of 255)
  return luminance < 128;
};

/**
 * Sets the appropriate text color based on the background color.
 * @param {string} backgroundColor Hex color string (e.g., '#RRGGBB').
 * @returns {string} 'white' or 'black' depending on the luminance.
 */
export const getTextColor = (backgroundColor) => {
  return isDarkColor(backgroundColor || "#96f7dc") ? "white" : "black";
};

export const showNotification = (notification) => {
  console.log("show notification", notification);
  const { title, id, image, vendor } = notification;
  console.log(Notification.permission);

  // const customNotification = new Notification(notification.title, {
  //   body: `${notification.vendor}`,
  //   icon: notification.image,
  // });

  // customNotification.onclick = () => {
  //   window.open(`/details/${notification.id}`, "_blank");
  // };
  return (
    <Snackbar
      open={true}
      autoHideDuration={6000}
      // onClose={() =>
      //   // setInAppNotification({ ...inAppNotification, open: false })
      // }
    >
      <Alert severity="info" sx={{ width: "100%" }}>
        <div style="display: flex; align-items: center;">
          <img
            src={`${notification.image}`}
            alt="Notification"
            // style={{
            //   "width: 40px; height: 40px; margin-right: 10px;"
            // }}
          />
          <div>
            <strong>{notification.title}</strong>
            <p style="margin: 0; font-size: 12px; color: #666;">
              {notification.vendor}
            </p>
          </div>
        </div>
      </Alert>
    </Snackbar>
  );
};
