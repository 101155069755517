import { Snackbar, Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useOrganisation } from "../hooks/useOrganisation";
import { router } from "./router/route";

function AppRoutes() {
  const { primaryFont } = useOrganisation();
  const [fontFamily, setFontFamily] = useState("Figtree");
  const [notification, setNotification] = useState<{
    open: boolean;
    message: string;
    url: string;
  } | null>(null);
  const organisationId = localStorage.getItem("organisationId");
  console.log(fontFamily);
  // Function to display browser or in-app notification
  function showNotification({
    title,
    id,
    image,
    vendor,
  }: {
    title: string;
    id: string;
    image: string;
    vendor: string;
  }) {
    const url = `https://komz.tech/article/details/${id}`; // Define the URL to navigate
    console.log("show notification", { title, id, image, vendor });

    const browserNotification = new Notification(title, {
      body: `From: ${vendor}`,
      // icon: image,
    });

    browserNotification.onclick = () => {
      window.open(url, "_blank");
    };
    setNotification({
      open: true,
      message: `${title} - From: ${vendor}`,
      url,
    });
  }

  // Handle dynamic font loading
  useEffect(() => {
    if (primaryFont) {
      const link = document.createElement("link");
      link.href = `https://fonts.googleapis.com/css2?family=${primaryFont.replace(
        " ",
        "+"
      )}&display=swap`;
      link.rel = "stylesheet";
      document.head.appendChild(link);
      setFontFamily(primaryFont);

      return () => {
        document.head.removeChild(link);
      };
    }
  }, [primaryFont]);

  // SignalR connection and notification handling
  useEffect(() => {
    if (organisationId) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl("https://komz.azurewebsites.net/notificationHub")
        .withAutomaticReconnect()
        .build();

      connection
        .start()
        .then(() => {
          console.log("Connected to SignalR");
          connection.invoke("JoinGroup", organisationId).then(() => {
            console.log(`Joined group: ${organisationId}`);
          });

          connection.on(
            "ReceiveMessage",
            (notification: {
              title: string;
              id: string;
              image: string;
              vendor: string;
            }) => {
              console.log("Receiving notification:", notification);
              showNotification(notification);
            }
          );
        })
        .catch((err) => console.error("SignalR Connection Error: ", err));

      return () => {
        connection.stop().then(() => console.log("Disconnected from SignalR"));
      };
    }
  }, [organisationId]);

  // MUI theme setup
  const theme = createTheme({
    typography: {
      fontFamily: fontFamily,
    },
  });

  // Handle Snackbar click and close actions
  const handleNotificationClick = () => {
    if (notification?.url) {
    }
    setNotification(null); // Close notification after handling
  };

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router()} />

      {/* Snackbar for in-app notifications */}
      {notification && (
        <Snackbar
          open={notification.open}
          autoHideDuration={60000}
          onClose={() => setNotification(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            severity="info"
            onClick={handleNotificationClick}
            sx={{ cursor: "pointer", width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </ThemeProvider>
  );
}

export default AppRoutes;
