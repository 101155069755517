import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

interface CustomSwitchProps {
  primaryColor: string;
}

export default styled(Switch, {
  shouldForwardProp: (prop) => prop !== "primaryColor", // Avoid passing the prop to the root component
})<CustomSwitchProps>(({ theme, checked, primaryColor }) => ({
  "& .MuiSwitch-switchBase": {
    color: checked ? primaryColor : theme.palette.error.main, // Use primaryColor prop
  },
  "& .MuiSwitch-track": {
    backgroundColor: checked ? "gray" : "gray",
  },
  ".Mui-checked+.MuiSwitch-track": {
    backgroundColor: checked ? "gray" : "gray",
  },
}));
