import {
  Grid,
  FormControlLabel,
  Box,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PublishDateSelector from "../../../common/Date";
import CustomSwitch from "../../../common/Swicth";
import Button from "../../../common/Button";
import { getRole } from "../../../../helpers/auth";
import { roles } from "../../../../constants/routes";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const ContentOption = ({
  setStep,
  step,
  shareable,
  newsLetter,
  featured,
  scheduledDate,
  setScheduledDate,
  setShareable,
  setFeatured,
  setNewsLetter,
  handleAddContent,
  endDate,
  setEndDate,
}) => {
  const role = getRole();
  const { primaryColor } = useOrganisation();
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item container m={2} spacing={2}>
        <Grid item display={"flex"} alignItems={"center"}>
          <Typography variant="body1">Shareable</Typography>
          <Tooltip title="This content can be shared with anyone.">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={shareable}
                onChange={(e) => setShareable(e.target.checked)}
                name="shareable"
                primaryColor={primaryColor}
              />
            }
          />
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          <Typography variant="body1">Featured</Typography>
          <Tooltip title="This content will be featured on the main page">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={featured}
                onChange={(e) => setFeatured(e.target.checked)}
                name="featured"
                primaryColor={primaryColor}
              />
            }
          />
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          <Typography variant="body1">Newsletter</Typography>
          <Tooltip title="This content should be included in the newsletter.">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={newsLetter}
                onChange={(e) => setNewsLetter(e.target.checked)}
                name="newsletter"
                primaryColor={primaryColor}
              />
            }
          />
        </Grid>
      </Grid>
      <PublishDateSelector
        scheduledDate={scheduledDate}
        setScheduledDate={setScheduledDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <Grid
        item
        container
        flex={1}
        justifyContent={"space-around"}
        flexWrap={"wrap"}
        m={2}
      >
        <Button
          text="Go back"
          backgroundColor="white"
          onClick={() => setStep(2)}
          style={{ marginRight: 2 }}
        />
        <Button
          text="Preview Before Submit"
          onClick={() => {
            setStep(step + 1);
          }}
          style={{ marginRight: 2 }}
        />
        <Button
          text={"Submit for approval"}
          onClick={() => {
            if (scheduledDate) {
              handleAddContent({ publish: false });
            }
          }}
        />
        {roles.Admin === role && (
          <Button
            text={"Publish"}
            onClick={() => {
              if (scheduledDate) {
                handleAddContent({ publish: true });
              }
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ContentOption;
