import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchFonts } from "../../../store/api/organisation";
import Button from "../../common/Button";

const GoogleFontSearchStep = ({ font, setFont, handleBack, handleNext }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFont, setSelectedFont] = useState("");

  const {
    data: fonts = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["googleFonts"],
    queryFn: fetchFonts,
    staleTime: 60 * 60 * 1000, // Cache data for 1 hour
  });

  // Filtered font options based on the search query
  const filteredFonts = fonts.filter((font) =>
    font.family.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Dynamically apply the selected font to the preview
  useEffect(() => {
    if (selectedFont) {
      const fontLink = document.createElement("link");
      fontLink.rel = "stylesheet";
      fontLink.href = `https://fonts.googleapis.com/css2?family=${selectedFont.replace(
        / /g,
        "+"
      )}&display=swap`;
      document.head.appendChild(fontLink);

      return () => {
        document.head.removeChild(fontLink);
      };
    }
  }, [selectedFont]);

  return (
    <Box>
      <Typography variant="h6">Search and choose your font</Typography>
      <Autocomplete
        fullWidth
        options={filteredFonts}
        getOptionLabel={(option) => option.family}
        isOptionEqualToValue={(option, value) => option.family === value.family}
        loading={isLoading}
        onInputChange={(event, value) => setSearchQuery(value)} // Handle dynamic search
        onChange={(event, value) => {
          if (value) {
            setFont(value.family);
            setSelectedFont(value.family);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Fonts"
            variant="outlined"
            sx={{ marginTop: 2 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.family}
            style={{ fontFamily: option.family }}
          >
            {option.family}
          </li>
        )}
        noOptionsText={isError ? "Error fetching fonts" : "No fonts found"}
      />

      {font && (
        <Box
          mt={4}
          style={{
            fontFamily: font,
            fontSize: "24px",
          }}
        >
          This is a preview of "{font}".
        </Box>
      )}
      <Box sx={{ marginTop: 4 }} display={"flex"} justifyContent={"end"}>
        <Button
          onClick={handleBack}
          sx={{
            marginRight: 2,
          }}
          style={{}}
          backgroundColor="white"
          text="Back"
        />

        <Button onClick={handleNext} text="Next" disabled={!font} />
      </Box>
    </Box>
  );
};

export default GoogleFontSearchStep;
