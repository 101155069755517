import React, { useEffect } from "react";
import Users from "../../../compnents/pages/Users";

const AllUsers = () => {
  useEffect(() => {
    document.title = `AllUsers`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main" tabIndex={0}>
      <section className="sectionCenter">
        <h1 style={{ color: "gray" }}>Dashboard</h1>
        <hr />
        <Users />
      </section>
    </main>
  );
};

export default AllUsers;
