import React, { useEffect } from "react";
import PreferencesPage from "../../compnents/pages/Settings/PreferenceCenter";

const Preferences = () => {
  useEffect(() => {
    document.title = `Notification Settings`;
    window.scrollTo(0, 0);
  });

  return (
    <main id="main" tabIndex={0}>
      <PreferencesPage />
    </main>
  );
};

export default Preferences;
