import { Box, Checkbox, MenuItem, Select, Typography } from "@mui/material";
import dayjs from "dayjs";
import { SwapVert as SwapVertIcon } from "@mui/icons-material";

export const columns = ({
  primaryColor,
  selectedIds,
  handleSelect,
  handleSelectAll,
  handleStatusChange,
  setSortBy,
  setIsDescending,
  isDescending,
  setOpenView,
  setContentPreview,
}) => [
  {
    accessorKey: "article",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsSomeRowsSelected().length === 10}
          onChange={() => handleSelectAll()}
          style={{ color: "white" }}
        />

        <div
          onClick={() => {
            setSortBy("Article");
            setIsDescending(!isDescending);
          }}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body2">Article</Typography>
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Checkbox
          checked={selectedIds.includes(row.original.id)}
          onChange={() => handleSelect(row.original.id)}
        />
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.article}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "topic",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("Topic");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Topic</Typography>
        <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "author",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("Author");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Author</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "dateCreated",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("DateCreated");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Date Created</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {dayjs(row.original.dateCreated).format("DD/MM/YYYY")}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "approvedDate",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("DateCreated");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Approved Date</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original?.approvedDate ? (
            dayjs(row.original?.approvedDate).format("DD/MM/YYYY")
          ) : (
            <Typography variant="body2">Need to be approved</Typography>
          )}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "scheduledDate",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("DateCreated");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Go Live Date</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {dayjs(row.original?.scheduledDate).format("DD/MM/YYYY")}
        </Box>
      </>
    ),
  },

  {
    accessorKey: "status",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("DateCreated");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Status</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Select
          value={row.original.status}
          onChange={(event) =>
            handleStatusChange(row.original.id, event.target.value)
          }
          sx={{ minWidth: 120 }}
          style={{
            border: "2px solid",

            borderColor:
              row.original.status === "Pending"
                ? "orange"
                : row.original.status === "Approved"
                ? primaryColor
                : "red",
            borderRadius: "24px",
          }}
        >
          {row.original.status === "Pending" && (
            <MenuItem style={{ backgroundColor: "none" }} value="Pending">
              Pending
            </MenuItem>
          )}
          <MenuItem style={{ backgroundColor: "none" }} value="Approved">
            Approved
          </MenuItem>
          <MenuItem style={{ backgroundColor: "none" }} value="Rejected">
            Rejected
          </MenuItem>
          {row.original.status === "Archived" && (
            <MenuItem style={{ backgroundColor: "none" }} value="Archived">
              Archived
            </MenuItem>
          )}
        </Select>
      </>
    ),
  },
  // {
  //   id: "view",
  //   header: "",
  //   cell: ({ row }) => (
  //     <Button
  //       variant="raised"
  //       onClick={() => {
  //         setOpenView(true);
  //         setContentPreview(row.original);
  //       }}
  //     >
  //       <VisibilityIcon fontSize="small" style={{ marginRight: "4px" }} /> View
  //     </Button>
  //   ),
  // },
];
