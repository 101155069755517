import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addOrganisation,
  editOrganisation,
  getUpdateTemplateOrganisation,
} from "../../../store/api/organisation";
import { emailValidator } from "../../../helpers/validator";
import { useOrganisation } from "../../../hooks/useOrganisation";

function AddEditModal({ open, handleClose, isEdit, organisationId }) {
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();

  const [formValues, setFormValues] = useState({
    title: "",
    email: "",
    phoneNumber: "",
    location: "",
  });
  const [errors, setErrors] = useState({});
  const { data: organisation } = useQuery({
    queryKey: ["organisationToEdit", organisationId],
    queryFn: () => getUpdateTemplateOrganisation(organisationId),
    enabled: isEdit && organisationId ? true : false,
  });

  useEffect(() => {
    if (isEdit && organisation) {
      setFormValues({
        title: organisation.title || "",
        email: organisation.email || "",
        location: organisation.location || "",
        phoneNumber: organisation.phoneNumber || "",
      });
    }
  }, [isEdit, organisation]);

  const { mutate: addMutate } = useMutation({
    mutationFn: (user) =>
      addOrganisation({
        title: formValues.title,
        email: formValues.email,
        phoneNumber: formValues.phoneNumber,
        location: formValues.location,
      }),
    onSuccess: (data) => {
      queryClient.refetchQueries("allOrganisation");
      setFormValues({
        title: "",
        email: "",
        phoneNumber: "",
        location: "",
      });
      handleClose();
    },
    onError: (error) => {
      // setResponseMessage("Content Approved");
    },
  });

  const { mutate: editMutate } = useMutation({
    mutationFn: (user) =>
      editOrganisation({
        title: formValues.title,
        email: formValues.email,
        phoneNumber: formValues.phoneNumber,
        location: formValues.location,
        organisationId: organisationId,
      }),
    onSuccess: (data) => {
      queryClient.refetchQueries("allOrganisation");
      setFormValues({
        title: "",
        email: "",
        phoneNumber: "",
        location: "",
      });
      handleClose();
    },
    onError: (error) => {
      // setResponseMessage("Content Approved");
    },
  });
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    let testEmail = emailValidator(formValues.email);
    tempErrors.title = formValues.title ? "" : "Title is required";
    // tempErrors.email =
    //   !formValues.email && !testEmail ? "" : "Email is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (isEdit) {
        editMutate();
      } else {
        addMutate();
      }
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: { xs: "90%", md: "500px" }, // responsive width
          maxHeight: "90vh", // ensures the modal doesn't grow beyond the screen height
          bgcolor: "background.paper",
          p: 4,
          m: "auto",
          mt: "2%",
          borderRadius: 2,
          boxShadow: 24,
          overflowY: "auto", // adds scroll functionality
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Add New Organisation
        </Typography>
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            fullWidth
            label="Title"
            name="title"
            value={formValues.title}
            onChange={handleChange}
            error={!!errors.title}
            helperText={errors.title}
            required
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formValues.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            required
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            value={formValues.phoneNumber}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Location"
            name="location"
            value={formValues.location}
            onChange={handleChange}
            margin="normal"
          />
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ background: primaryColor, color: "black" }}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}

export default AddEditModal;
