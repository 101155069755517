import React, { useEffect } from "react";
import ArticleDetailsPage from "../../../compnents/pages/Dashboard/ArticleDetails";

const ArticleDetails = () => {
  useEffect(() => {
    document.title = `Article Details`;
    window.scrollTo(0, 0);
  });
  return (
    <main id="main" tabIndex={0}>
      <ArticleDetailsPage />;
    </main>
  );
};

export default ArticleDetails;
