import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import LoginPage from "../../../compnents/pages/Login";
import { verifyOrganisation } from "../../../store/api/organisation";

const Login = ({ superadmin }) => {
  const { companyName } = useParams();
  const navigate = useNavigate();
  const organisationId = localStorage.getItem("organisationId");

  const { mutate } = useMutation({
    mutationFn: verifyOrganisation,
    onSuccess: (data) => {
      localStorage.setItem("organisationId", data);
    },
    onError: (error) => {
      if (error.response) {
        navigate(`/`);
      }
    },
  });

  useEffect(() => {
    if (companyName && !organisationId) {
      mutate({ organisationName: companyName || "" });
    }
  }, [companyName]);

  useEffect(() => {
    document.title = `Login | ${!superadmin ? `${companyName}` : "superAdmin"}`;
    window.scrollTo(0, 0);
  }, [companyName, superadmin]);

  return (
    <main id="main" tabIndex={0}>
      <LoginPage superadmin={superadmin} companyName={companyName} />
    </main>
  );
};

export default Login;
