// DataCard.js
import React from "react";
import { Paper, Typography } from "@mui/material";
import { useOrganisation } from "../../../hooks/useOrganisation";

const DataCard = ({ title, count, onClick }) => {
  const { primaryColorLight } = useOrganisation();

  return (
    <Paper
      elevation={3}
      sx={{
        textAlign: "center",
        padding: 2,
        backgroundColor: primaryColorLight,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h4">{count}</Typography>
    </Paper>
  );
};

export default DataCard;
