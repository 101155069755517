import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { IconButton, Box, Tooltip, Snackbar, Typography } from "@mui/material";
import { LinkedIn, ContentCopy, Email, X } from "@mui/icons-material";
import { shareContentAnalytics } from "../../../../store/api/analytics";

const ShareHeader = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const { id } = useParams();
  const { mutate: shareMutate } = useMutation({
    mutationFn: () => shareContentAnalytics({ contentId: id }),
  });

  const handleLinkedInShare = () => {
    const articleUrl = window.location.href;
    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      articleUrl
    )}`;
    // Open LinkedIn Share in a new window
    window.open(linkedInShareUrl, "_blank", "noopener,noreferrer");
    shareMutate(id);
  };

  const handleEmailShare = () => {
    const subject = encodeURIComponent("Check out this article");
    const body = encodeURIComponent(
      `I found this interesting article for you: ${window.location.href}`
    );
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
    shareMutate(id);
  };

  const handleTwitterShare = () => {
    const tweetText = encodeURIComponent("Check out this article!");
    const tweetUrl = encodeURIComponent(window.location.href);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`;
    window.open(twitterUrl, "_blank");
    shareMutate(id);
  };
  const handleCopyLink = () => {
    const articleUrl = window.location.href;

    navigator.clipboard.writeText(articleUrl).then(() => {
      setCopySuccess(true); // Show success message
    });
    shareMutate(id);
  };
  const handleCloseSnackbar = () => {
    setCopySuccess(false);
  };
  return (
    <Box display="flex" alignItems="center" padding={2}>
      <Tooltip title="Share on LinkedIn">
        <IconButton
          onClick={handleLinkedInShare}
          aria-label="Share on LinkedIn"
        >
          <LinkedIn style={{ color: "#2677b5" }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on Twitter">
        <IconButton
          onClick={handleTwitterShare}
          aria-label="Share on Twitter (X)"
        >
          <X />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share via Email">
        <IconButton onClick={handleEmailShare} aria-label="Share via Email">
          <Email />
        </IconButton>
      </Tooltip>

      <Tooltip title="Copy link">
        <IconButton onClick={handleCopyLink}>
          <ContentCopy />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="Link copied!"
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Customize position as needed
      />
    </Box>
  );
};

export default ShareHeader;
