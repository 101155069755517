import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Header from "../../compnents/common/Header";
import Loading from "../../compnents/common/Loading";
import { useCheckAuth } from "../../hooks/auth";
import { getRole } from "../../helpers/auth";
import { roles } from "../../constants/routes";

const PrivateRoute = ({ element, requiredRoles }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentRole = getRole();
  const token = localStorage.getItem("token");
  const { refetch } = useCheckAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [hasAccess, setHasAccess] = useState(true); // Track if the user has access
  let role = getRole();

  useEffect(() => {
    refetch()
      .then((res) => {
        setIsAuthenticated(res.data);
        setIsAuthenticated(res.data);
      })
      .catch(() => setIsAuthenticated(false));
  }, [location, refetch]);

  // Check user access when authentication state changes
  useEffect(() => {
    if (!role || !requiredRoles.includes(role)) {
      setHasAccess(false);
    } else {
      setHasAccess(true);
    }
  }, [isAuthenticated, role, requiredRoles]);

  // Redirect if the user does not have access
  useEffect(() => {
    if (isAuthenticated && hasAccess) {
      if (currentRole === roles.SuperAdmin) {
        navigate("/admin-dashboard", { replace: true });
      }
      if (currentRole === roles.Reader || currentRole === roles.Editor) {
        navigate("/dashboard", { replace: true });
      }
    }
  }, [isAuthenticated, currentRole, hasAccess]);

  // Show loading while authentication is being checked
  if (isAuthenticated === null) {
    return <Loading />;
  }

  // Render the element if authenticated and has access
  if (isAuthenticated && hasAccess) {
    return (
      <>
        <Header />
        {element}
      </>
    );
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
};

export default PrivateRoute;
