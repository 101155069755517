import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// Define types for the add Type
interface addTypeParams {
  title: string;
}

interface TypeResponse {
  msg: string;
}

/**
 * @method POST
 * @route
 * @body {Object} url
 * @returns {Promise<ContentResponse>}
 */
export const addType = async (title: addTypeParams): Promise<TypeResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  try {
    const response: AxiosResponse<TypeResponse> = await axios.post(
      `${URL.MANAGE_TYPE.BASE}${URL.MANAGE_TYPE.V1_ADD_TYPE}`,
      {
        title,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// -----------------------------------------------------
// Define types for GET all types for admin

interface GetTypesResponse {
  paginatedContent: [];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}
interface getTypesParams {
  searchTerm: string;
  currentPage: string;
  sortBy: string;
  isDescending: boolean;
}
/**
 * @method GET
 * @route
 * @returns {Promise<GetTypesResponse>}
 */
export const getAllTypes = async ({
  searchTerm,
  currentPage,
  sortBy,
  isDescending,
}: getTypesParams): Promise<GetTypesResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<GetTypesResponse> = await axios.get(
    `${URL.MANAGE_TYPE.BASE}${URL.MANAGE_TYPE.V1_GET_ALL_TYPES}?searchTerm=${searchTerm}&pageNumber=${currentPage}&pageSize=10&sortBy=${sortBy}&isDescending=${isDescending}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for edit type by admin

interface UpdateTypeParams {
  title: string;
  id: string;
}

interface typeUpdateResponse {
  msg?: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<typeUpdateResponse>} returns a Promise either an error or the response object
 */
export const editType = async ({
  title,
  id,
}: UpdateTypeParams): Promise<typeUpdateResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<typeUpdateResponse> = await axios.put(
    `${URL.MANAGE_TYPE.BASE}${URL.MANAGE_TYPE.V1_EDIT_TYPE}/${id}`,
    { title },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for delete type

interface deleteTypeParams {
  typeIds: string;
}
interface TypeResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<TypeResponse>}
 */
export const deleteType = async (
  typeIds: deleteTypeParams
): Promise<TypeResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<TypeResponse> = await axios.put(
    `${URL.MANAGE_TYPE.BASE}${URL.MANAGE_TYPE.V1_DELETE_TYPE}`,
    typeIds,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for restore type

interface restoreTypeParams {
  typeIds: string;
}
interface TypeResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<TypeResponse>}
 */
export const restoreType = async (
  typeIds: restoreTypeParams
): Promise<TypeResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<TypeResponse> = await axios.put(
    `${URL.MANAGE_TYPE.BASE}${URL.MANAGE_TYPE.V1_RESTORE_TYPE}`,
    typeIds,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
