import React, { useState, useCallback } from "react";
import { Box, Grid, Card } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Bar } from "react-chartjs-2";

import Table from "../../../common/Table";
import Loading from "../../../common/Loading";
import Button from "../../../common/Button";
import DataCard from "../DataCard";

import { columns } from "./columns";
import { exportToCSV } from "../../../../helpers";
import {
  getAllActiveUserStatistics,
  getAllBouncesUserStatistics,
  getAllLoginUserStatistics,
  getAllSubscriptionUserStatistics,
  getAllUnActiveUserStatistics,
  getAllUnSubscriptionUserStatistics,
  getUserStatistics,
} from "../../../../store/api/statistics";

// Reusable TabPanel component
const TabPanel = ({ value, index, children }) => {
  return value === index && <Box>{children}</Box>;
};

const UserTab = ({ tabIndex, index, startDate, endDate, organisation }) => {
  const [selectedCard, setSelectedCard] = useState(null);

  // Query for overall user statistics
  const { data: statistics } = useQuery({
    queryKey: ["userStatistics", startDate, endDate],
    queryFn: () =>
      getUserStatistics({
        startDate,
        endDate,
        pageNumber: 1,
      }),
  });

  // Fetch data based on the selected card
  const { data: usersStatistics, isLoading: loadUsers } = useQuery({
    queryKey: [selectedCard?.key, startDate, endDate],
    queryFn: () =>
      selectedCard.getDataFunction({ startDate, endDate, pageNumber: 1 }),
    enabled: !!selectedCard, // Only run this query if a card is selected
  });

  // Bar chart data
  const data = {
    labels: statistics?.engagements?.map((el) => el.name) || [],
    datasets: [
      {
        label: "Engagement",
        data: statistics?.engagements?.map((el) => el.count) || [],
        borderWidth: 1,
        backgroundColor: "grey",
      },
    ],
  };

  const handleUserList = useCallback((selected) => {
    switch (selected) {
      case "active":
        setSelectedCard({
          key: "active",
          getDataFunction: getAllActiveUserStatistics,
        });
        break;
      case "unactive":
        setSelectedCard({
          key: "unactive",
          getDataFunction: getAllUnActiveUserStatistics,
        });
        break;
      case "subscribers":
        setSelectedCard({
          key: "subscribers",
          getDataFunction: getAllSubscriptionUserStatistics,
        });
        break;
      case "unsubscribers":
        setSelectedCard({
          key: "unsubscribers",
          getDataFunction: getAllUnSubscriptionUserStatistics,
        });
        break;
      case "logins":
        setSelectedCard({
          key: "logins",
          getDataFunction: getAllLoginUserStatistics,
        });
        break;
      case "bounces":
        setSelectedCard({
          key: "bounces",
          getDataFunction: getAllBouncesUserStatistics,
        });
        break;
      default:
        setSelectedCard(null);
        break;
    }
  }, []);

  const chartOptions = {
    scales: {
      y: { beginAtZero: true },
      x: {
        categoryPercentage: 0.6,
        barPercentage: 0.5,
      },
    },
    responsive: true,
  };

  return (
    <TabPanel value={tabIndex} index={index}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <Bar data={data} options={chartOptions} />
          </Card>
        </Grid>

        {/* Data Cards */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <DataCard
                title="Subscribers"
                count={statistics?.subscribers || 0}
                onClick={() => handleUserList("subscribers")}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <DataCard
                title="Logins"
                count={statistics?.logins || 0}
                onClick={() => handleUserList("logins")}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <DataCard
                title="Active Users"
                count={statistics?.active || 0}
                onClick={() => handleUserList("active")}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <DataCard
                title="Inactive Users"
                count={statistics?.inactive || 0}
                onClick={() => handleUserList("unactive")}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <DataCard
                title="Unsubscribes"
                count={statistics?.unsubscribers || 0}
                onClick={() => handleUserList("unsubscribers")}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <DataCard
                title="Bounces"
                count={statistics?.bounces || 0}
                onClick={() => handleUserList("bounces")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Table and Export Button */}
      <Grid
        style={{ marginTop: "1em", display: "flex", justifyContent: "end" }}
      >
        <Button
          text="Export CSV"
          onClick={() => exportToCSV(usersStatistics?.paginatedContent)}
        />
      </Grid>
      {loadUsers && selectedCard ? (
        <Loading />
      ) : (
        <Table data={usersStatistics?.paginatedContent} columns={columns({})} />
      )}
    </TabPanel>
  );
};

export default UserTab;
