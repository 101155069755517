import React, { useEffect } from "react";
import AddContentPage from "../../../compnents/pages/AddContent";
import { useParams } from "react-router-dom";

const CreateContent = () => {
  const { contentId } = useParams();
  useEffect(() => {
    document.title = contentId ? `Edit Content` : `Create Content`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main" tabIndex={0}>
      <AddContentPage />
    </main>
  );
};

export default CreateContent;
