import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "../../../common/Button";
import "./style.css";

const ContentType = ({ step, setStep }) => {
  return (
    <Box className="typeContainer">
      <Typography variant="body1">
        What type of content are you adding?
      </Typography>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button text={"Custom"} onClick={() => setStep(2)} />
        <Button text={"From a URL"} onClick={() => setStep(1)} />
      </div>
    </Box>
  );
};

export default ContentType;
