import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Box, Typography, Link, Button } from "@mui/material";

import Input from "../../common/Input";
import Toast from "../../common/Toast";
import Footer from "../../common/Footer";

import { login, superAdminLogin } from "../../../store/api/users";
import { getOnboardedOrganisation } from "../../../store/api/organisation";
import { useOrganisation } from "../../../hooks/useOrganisation";
import "./style.css";

const Login = ({ superadmin, companyName }) => {
  const { primaryColor } = useOrganisation();
  const token = localStorage.getItem("token");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isToken, setIsToken] = useState(false);
  const [errors, setErrors] = useState({ email: false, password: false });
  const [toast, setToast] = useState({ message: null, status: null });

  const navigate = useNavigate();
  const { data, isFetched } = useQuery({
    queryKey: ["onboardedOrganisation", { context: "login" }],
    queryFn: () => getOnboardedOrganisation({}),
    enabled: !!token && !superadmin, // Ensure the query only runs when a token is present
  });

  //adminLogin
  const { mutate } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      localStorage.setItem("token", JSON.stringify(data.accessToken));
      localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));
      setToast({ message: "Login successful!", status: 200 });
      setIsToken(true);
    },
    onError: (error) => {
      let errorMessage = "Something went wrong.";
      let status = null;
      if (error.response) {
        status = error.response.status;
        console.log(error.response);
        switch (status) {
          //TODO create reusable funcion for status and toast
          case 400:
            errorMessage =
              error.response.data.title ||
              "Bad request. Please check your input.";
            break;
          case 401:
            errorMessage =
              "Incorrect email or password. Please check your credentials.";
            break;
          case 500:
            errorMessage = "Server error. Please try again later.";
            break;
          default:
            errorMessage = "Something went wrong.";
        }
      }

      setToast({ message: errorMessage, status });
    },
  });

  //SuperadminLogin
  const { mutate: SuperAdminMutate, isPending: SuperAdminPending } =
    useMutation({
      mutationFn: superAdminLogin,
      onSuccess: (data) => {
        //TODO save user Data
        localStorage.setItem("token", JSON.stringify(data.accessToken));
        localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));
        setToast({ message: "Login successful!", status: 200 });
        navigate("/admin-dashboard");
      },
      onError: (error) => {
        let errorMessage = "Something went wrong.";
        let status = null;
        if (error.response) {
          status = error.response.status;
          switch (status) {
            //TODO create reusable funcion for status and toast
            case 400:
              errorMessage = "Bad request. Please check your input.";
              break;
            case 401:
              errorMessage =
                "Incorrect email or password. Please check your credentials.";
              break;
            case 500:
              errorMessage = "Server error. Please try again later.";
              break;
            default:
              errorMessage = "Something went wrong.";
          }
        }

        setToast({ message: errorMessage, status });
      },
    });

  useEffect(() => {
    if (isFetched) {
      if (data?.isOnBoarded === false) {
        // If the organisation is not onboarded
        console.log(
          "Organisation is not onboarded. Redirecting to onboarding."
        );
        navigate("/onboarding");
      } else if (data?.isOnBoarded === true) {
        // If the organisation is onboarded
        console.log("Organisation is onboarded. Redirecting to dashboard.");
        navigate("/dashboard");
      } else if (!token) {
        // If token is missing, redirect to login
        console.log("Token is missing. Redirecting to login.");
        navigate("/login");
      }
    }
  }, [data, isFetched, token, navigate]);

  const SubmitForm = () => {
    let hasError = false;
    const newErrors = { email: false, password: false };
    let organisationId = localStorage.getItem("organisationId");
    if (email.trim() === "") {
      newErrors.email = true;
      hasError = true;
    }

    if (password.trim() === "") {
      newErrors.password = true;
      hasError = true;
    }
    setErrors(newErrors);
    if (!hasError) {
      if (superadmin) {
        SuperAdminMutate({
          email: email,
          password: password,
        });
      } else {
        mutate({
          email: email,
          password: password,
          organisationId,
        });
      }
    }
  };

  const colors = {
    text: "#000000",
    link: "#333333",
    background: "#ffffff",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: colors.background,
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography
          variant="h5"
          textAlign="center"
          color={colors.text}
          fontWeight="bold"
        >
          Log in
        </Typography>
        <Typography
          variant="h6"
          textAlign="center"
          color={colors.text}
          fontWeight="bold"
        >
          {companyName && companyName?.toUpperCase()}
        </Typography>
        <Input
          required={true}
          id="email"
          name="email"
          onChange={setEmail}
          value={email}
          label="Email address"
          type="email"
          error={errors.email}
          helperText={errors.email ? "Email is required" : ""}
        />
        <Input
          required={true}
          onChange={setPassword}
          value={password}
          label="Password"
          type="password"
          error={errors.password}
          helperText={errors.password ? "Password is required" : ""}
        />
        <Link
          href={"/forgetPassword"}
          color={colors.link}
          sx={{ marginBottom: 2, textAlign: "center" }}
        >
          Forgot your password?
        </Link>

        <Button
          variant="raised"
          fullWidth
          sx={{ backgroundColor: primaryColor, color: "#000" }}
          onClick={SubmitForm}
        >
          Continue
        </Button>
      </Box>
      <Link href={"/"} color={colors.link} sx={{ marginBottom: 2 }}>
        Swicth company name
      </Link>
      <Footer />
      {toast.message && <Toast message={toast.message} status={toast.status} />}
    </Box>
  );
};

export default Login;
