import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Box, Grid } from "@mui/material";
import ContentType from "./ContentType";
import ContentForm from "./ContentForm";
import ContentURL from "./ContentURL";
import ContentOption from "./ContentOption";
import ContentPreview from "./ContentPreview";
import {
  addArticle,
  approveContent,
  editArticle,
  extractContent,
  getArticleByid,
  getUpdateArticleTemplateByid,
} from "../../../store/api/content";
import { convertBlobToBase64 } from "../../../helpers/validator";
import "./style.css";

const AddContentPage = () => {
  const navigate = useNavigate();
  const { contentId } = useParams();

  const [step, setStep] = useState(0);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [topics, setTopics] = useState([]);
  const [types, setTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [image, setImage] = useState(null);
  const [imageList, setimageList] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [content, setContent] = useState("");
  const [summaryError, setSummaryError] = useState(false);
  const [isContentFromUrl, setIsContentFromUrl] = useState(false);
  const [url, setURL] = useState("");
  const [shareable, setShareable] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [newsLetter, setNewsLetter] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [publish, setPublish] = useState(false);

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["articleToEdit", contentId],
    queryFn: () => getArticleByid({ id: contentId }),
    enabled: !!contentId,
  });
  const { data: topicsData } = useQuery({
    queryKey: ["DataForarticleToEdit", contentId],
    queryFn: () => getUpdateArticleTemplateByid({ id: contentId }),
    enabled: !!contentId,
  });

  const { mutate: approveMutate } = useMutation({
    mutationFn: (data) => approveContent({ contentId: data }),
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  const { mutate, isPending } = useMutation({
    mutationFn: extractContent,
    onSuccess: async (data) => {
      try {
        // Handle image fetching
        const response = await fetch(data.mainImageUrls[0]);
        setimageList(data.mainImageUrls);
        if (!response.ok) throw new Error("Image fetch failed");
        const blob = await response.blob();
        const fileName = data.mainImageUrls[0].split("/").pop();
        const contentType = response.headers.get("Content-Type");
        const fileData = await convertBlobToBase64(blob);
        setImage({
          fileData,
          fileName,
          contentType,
        });
      } catch {
        setImageError(true);
        setImage(null);
      }

      // Handle title
      if (data.title) {
        setTitle(data.title);
      } else {
        setTitleError(true);
        setTitle("");
      }

      // Handle summary
      if (data.summary) {
        setContent(data.summary);
      } else {
        setSummaryError(true);
        setContent("");
      }

      setStep(2);
    },
    onError: () => {
      setImageError(true);
      setTitleError(true);
      setSummaryError(true);
    },
  });

  const { mutate: postContentMutate } = useMutation({
    mutationFn: addArticle,
    onSuccess: (data) => {
      if (publish) {
        approveMutate(data);
      }
      navigate("/admin-dashboard");
    },
    onError: () => {
      alert("Failed to add content");
    },
  });
  const { mutate: updateContentMutate } = useMutation({
    mutationFn: editArticle,
    onSuccess: (data) => {
      navigate("/admin-dashboard");
    },
    onError: () => {
      alert("Failed to add content");
    },
  });

  useEffect(() => {
    if (data && topicsData) {
      setTitle(topicsData.title || "");
      setTopics(topicsData?.topics.filter((el) => el.isSelected) || []);
      setRegions(topicsData?.regions.filter((el) => el.isSelected) || []);
      setTypes(topicsData?.types.filter((el) => el.isSelected) || []);
      setImage(topicsData?.mainImage || null);
      setContent(topicsData.contentText || "");
      setScheduledDate(topicsData?.scheduledDate);
      setEndDate(topicsData?.endDate);
      setShareable(topicsData?.shareable);
      setFeatured(topicsData?.featured);
      setNewsLetter(topicsData?.newsLetter);
    }
  }, [data, contentId, topicsData]);

  const SubmitForm = () => {
    setIsContentFromUrl(true);
    mutate({ url });
  };

  const handleAddContent = ({ publish }) => {
    if (contentId) {
      updateContentMutate({
        contentId,
        title,
        contentText: content,
        contentUrl: url,
        shareable,
        featured,
        newsLetter,
        scheduledDate,
        endDate,
        contentTopicIds: topics.map((el) => el.id),
        contentRegionIds: regions.map((el) => el.id),
        contentTypeIds: types.map((el) => el.id),
        mainImage: image,
      });
    } else {
      if (publish) {
        setPublish(true);
        postContentMutate({
          title,
          contentText: content,
          isContentFromUrl,
          contentUrl: url,
          shareable,
          featured,
          newsLetter,
          scheduledDate,
          endDate,
          contentTopicIds: topics.map((el) => el.id),
          contentRegionIds: regions.map((el) => el.id),
          contentTypeIds: types.map((el) => el.id),
          mainImage: image,
        });
      } else {
        setPublish(false);
        postContentMutate({
          title,
          contentText: content,
          isContentFromUrl,
          contentUrl: url,
          shareable,
          featured,
          newsLetter,
          scheduledDate,
          endDate,
          contentTopicIds: topics.map((el) => el.id),
          contentRegionIds: regions.map((el) => el.id),
          contentTypeIds: types.map((el) => el.id),
          mainImage: image,
        });
      }
    }
  };

  const createContentStep = () => {
    if (contentId) {
      switch (step) {
        case 0:
          return (
            <ContentForm
              step={step}
              setStep={setStep}
              setImage={setImage}
              setContent={setContent}
              content={content}
              image={image}
              setTitle={setTitle}
              title={title}
              topics={topics}
              setTopics={setTopics}
              types={types}
              setTypes={setTypes}
              regions={regions}
              setRegions={setRegions}
              isContentFromUrl={isContentFromUrl}
              imageError={imageError}
              titleError={titleError}
              summaryError={summaryError}
            />
          );
        case 1:
          return (
            <ContentOption
              step={step}
              setStep={setStep}
              shareable={shareable}
              newsLetter={newsLetter}
              featured={featured}
              scheduledDate={scheduledDate}
              setShareable={setShareable}
              setFeatured={setFeatured}
              setNewsLetter={setNewsLetter}
              setScheduledDate={setScheduledDate}
              handleAddContent={handleAddContent}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          );
        case 2:
          return (
            <ContentPreview
              step={step}
              setStep={setStep}
              content={content}
              image={image}
              title={title}
              topics={topics}
              scheduledDate={scheduledDate}
              handleAddContent={handleAddContent}
            />
          );
        default:
          return <h1>No project match</h1>;
      }
    } else {
      switch (step) {
        case 0:
          return <ContentType step={step} setStep={setStep} />;
        case 1:
          return (
            <ContentURL
              step={step}
              setStep={setStep}
              setIsContentFromUrl={setIsContentFromUrl}
              url={url}
              setURL={setURL}
              SubmitForm={SubmitForm}
              isPending={isPending}
              setImageError={setImageError}
              setTitleError={setTitleError}
              setSummaryError={setSummaryError}
            />
          );
        case 2:
          return (
            <ContentForm
              setStep={setStep}
              step={step}
              setImage={setImage}
              setContent={setContent}
              content={content}
              image={image}
              imageList={imageList}
              setTitle={setTitle}
              title={title}
              topics={topics}
              setTopics={setTopics}
              types={types}
              setTypes={setTypes}
              regions={regions}
              setRegions={setRegions}
              isContentFromUrl={isContentFromUrl}
              imageError={imageError}
              titleError={titleError}
              summaryError={summaryError}
            />
          );
        case 3:
          return (
            <ContentOption
              step={step}
              setStep={setStep}
              shareable={shareable}
              newsLetter={newsLetter}
              featured={featured}
              scheduledDate={scheduledDate}
              setShareable={setShareable}
              setFeatured={setFeatured}
              setNewsLetter={setNewsLetter}
              setScheduledDate={setScheduledDate}
              handleAddContent={handleAddContent}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          );
        case 4:
          return (
            <ContentPreview
              step={step}
              setStep={setStep}
              content={content}
              image={image}
              title={title}
              topics={topics}
              scheduledDate={scheduledDate}
              handleAddContent={handleAddContent}
            />
          );
        default:
          return <h1>No project match</h1>;
      }
    }
  };

  return (
    <section className=" contentContainer">
      <Grid container spacing={2} justifyContent={"center"} height={100}>
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          justifyContent={"center"}
          item
          xs={12}
          md={12}
        >
          <Box m={2}>
            <h1>Create Content</h1>
          </Box>
        </Grid>
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          justifyContent={"center"}
          item
          xs={12}
          md={12}
        >
          <Box className="">{createContentStep()}</Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default AddContentPage;
