import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import DownloadIcon from "@mui/icons-material/Download";
import { createExcelTemplate } from "./template";

const AddCSV = ({ handleNextStep, handleBackStep, csvData, handleDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
  });
  const [download, setDownload] = useState(false);

  return (
    <div>
      <div>
        <Button
          onClick={() => {
            createExcelTemplate();
            setDownload(true);
          }}
        >
          Download Template
        </Button>
        <Box
          sx={{
            pointerEvents: download ? "auto" : "none",
            opacity: download ? 1 : 0.5,
            mt: 3,
          }}
        >
          <div
            {...getRootProps()}
            style={{
              border: "1px dashed gray",
              padding: "20px",
              marginTop: "20px",
              color: download ? "black" : "gray",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: download ? "pointer" : "not-allowed",
            }}
          >
            <DownloadIcon />
            <input disabled={!download} {...getInputProps()} />
            <Typography variant="body1">
              Drag and drop or click to upload a CSV file
            </Typography>
          </div>
        </Box>
      </div>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <Button
          variant="contained"
          onClick={() => {
            handleNextStep();
          }}
          disabled={!csvData.length}
          style={{
            marginBottom: 8,
            background: csvData.length ? "black" : "gray",
            color: "white",
            cursor: csvData.length ? "pointer" : "notAllowed",
          }}
        >
          Upload CSV file
        </Button>
        <Button
          variant="outlined"
          onClick={handleBackStep}
          style={{ color: "black", border: "1px solid black" }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default AddCSV;
