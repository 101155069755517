import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";

export const columns = ({}) => [
  {
    accessorKey: "Date",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2">Date</Typography>
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.title}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "name",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2">Name</Typography>
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.name}
        </Box>
      </>
    ),
  },

  {
    accessorKey: "Company",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Company</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {dayjs(row.original.dateCreated).format("DD/MM/YYYY")}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "Email",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        // onClick={() => {
        //   setSortBy("status");
        //   setIsDescending(!isDescending);
        // }}
      >
        <Typography variant="body2">Email</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.email}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "No Logins",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        // onClick={() => {
        //   setSortBy("status");
        //   setIsDescending(!isDescending);
        // }}
      >
        <Typography variant="body2">No Logins</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {"_"}
        </Box>
      </>
    ),
  },
];
