import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useOrganisation } from "../../../hooks/useOrganisation";

const Loading = ({ color = "" }) => {
  const { primaryColor } = useOrganisation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{
          color: color ? color : primaryColor,
        }}
      />
    </div>
  );
};

export default Loading;
