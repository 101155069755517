import React, { useEffect } from 'react'
import ForgetPasswordPage from '../../../compnents/pages/ForgetPassword'

const ForgetPassword = () => {
    useEffect(()=>{
        document.title = `Forget Password`
        window.scrollTo(0, 0)
    },[])
   
    return (
        <main id="main" tabIndex={0}>
         <ForgetPasswordPage/>
        </main>
    )
}

export default ForgetPassword