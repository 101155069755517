import { Box, Grid } from "@mui/material";
import React from "react";
import ArticleCard from "../../../common/ArticleCard";

const SearchedContent = ({ searchedContent, isMobile, isTablet }) => {
  return (
    <section className="sectionCenter">
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={isMobile ? 1 : 2}>
          {searchedContent?.paginatedContent.map((article) => (
            <ArticleCard
              article={article}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          ))}
        </Grid>
      </Box>
    </section>
  );
};

export default SearchedContent;
