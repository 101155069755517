import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import Table from "../../../common/Table";
import AdFormModal from "./AddEdit";
import { columns } from "./columns";

import { getAllAds } from "../../../../store/api/ads";
import { deleteAds } from "../../../../store/api/ads";

const Ads = ({ tabIndex, index }) => {
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("DateCreated");
  const [isDescending, setIsDescending] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenCofirmation] = useState(false);
  const [adToDelete, setAdToDelete] = useState(null);

  const { data } = useQuery({
    queryKey: ["adsList", searchTerm, currentPage, sortBy, isDescending],
    queryFn: () => getAllAds({ searchTerm, currentPage, sortBy, isDescending }),
    refetchOnWindowFocus: true,
    staleTime: 0,
  });
  const { mutate: deleteMutate } = useMutation({
    mutationFn: (id) => deleteAds({ id }),
    onSuccess: (data) => {
      queryClient.refetchQueries("adsList");
      handleCloseConfirmation();
      // setStatus(200);
      // setResponseMessage("Added successfullly");
    },
    onError: (error) => {
      // setStatus(400);
      // setResponseMessage("error accured");
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenConfirmation = () => {
    setOpenCofirmation(true);
  };
  const handleCloseConfirmation = () => {
    setOpenCofirmation(false);
  };
  const handleConfirmDelete = () => {
    deleteMutate(adToDelete);
  };
  const handleEdit = (data) => {
    setIsEdit(true);
    setEditData(data);
    handleOpen();
  };
  const handleDelete = (ads) => {
    handleOpenConfirmation();
    console.log(ads);
    setAdToDelete(ads.id);
  };

  return (
    <Box>
      <Grid container xs={12} mb={2} md={12}>
        <AdFormModal
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          isEdit={isEdit}
          editData={editData}
        />
      </Grid>
      <Table data={data} columns={columns({ handleEdit, handleDelete })} />;
      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this Ad{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation}>Disagree</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Ads;
