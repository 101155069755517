import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  SwapVert as SwapVertIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

export const columns = ({ handleEdit, handleDelete }) => [
  {
    accessorKey: "type",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2">Article</Typography>
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.type}
        </Box>
      </>
    ),
  },

  {
    accessorKey: "dateCreated",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Date Created</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {dayjs(row.original.dateCreated).format("DD/MM/YYYY")}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "order",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Position</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.order}
        </Box>
      </>
    ),
  },

  {
    id: "edit",
    header: "",
    cell: ({ row }) => (
      <Button
        variant="raised"
        onClick={() => {
          handleEdit(row.original.id);
        }}
      >
        Edit
      </Button>
    ),
  },
  {
    id: "delete",
    header: "",
    cell: ({ row }) => (
      <Button
        variant="raised"
        onClick={() => {
          handleDelete(row.original);
        }}
      >
        <DeleteIcon />
      </Button>
    ),
  },
];
