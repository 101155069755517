import React, { useState } from "react";
import { Modal, IconButton, Typography } from "@mui/material";
import Papa from "papaparse";
import AddGroup from "./AddGroup";
import AddTypeUser from "./AddTypeUser";
import AddCSV from "./AddCSV";
import { Close } from "@mui/icons-material";
import UserList from "./UserList";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadUsersByAdmin } from "../../../../store/api/users";

const UploadUsersModal = ({ open, onClose }) => {
  const queryClient = useQueryClient();

  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedTypeTitle, setSelectedTypeTitle] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [file, setFile] = useState(null);
  const [step, setStep] = useState(1);
  const [editingRow, setEditingRow] = useState(null);

  const { mutate: uploadMutate } = useMutation({
    mutationFn: () =>
      uploadUsersByAdmin({
        users: csvData,
        userTypeId: selectedType,
      }),
    onSuccess: (data) => {
      queryClient.refetchQueries("adminUsers");
      onClose();
    },
    onError: (error) => {
      // setResponseMessage("Content Approved");
    },
  });

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };
  const handleTypeChange = (event, data) => {
    const idType = event.target.value;
    setSelectedType(idType);
    setSelectedTypeTitle(data.find((el) => el.id === idType)?.title);
  };

  const handleDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
    Papa.parse(acceptedFiles[0], {
      header: true,
      complete: (result) => {
        setCsvData(result.data);
      },
    });
  };

  const handleEditRow = (index) => {
    setEditingRow(index);
  };

  const handleSaveRow = (index, field, value) => {
    const updatedData = [...csvData];
    updatedData[index][field] = value;
    setCsvData(updatedData);
    setEditingRow(null);
  };

  const handleSubmit = () => {
    console.log("Submitting data:", csvData);
    uploadMutate();
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleBackStep = () => {
    setStep(step - 1);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div
        className="modal-content"
        style={{
          padding: "20px",
          backgroundColor: "white",
          margin: "1% auto",
          maxWidth: "600px",
          transform: "translate(-50%, -50%)",
          maxHeight: "90vh",
          overflowY: "auto",
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <div style={headerStyle}>
          <Typography
            variant="h6"
            component="h2"
            style={{ fontWeight: "bold" }}
          >
            Add users
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        {step === 1 && (
          <AddGroup
            selectedGroup={selectedGroup}
            handleGroupChange={handleGroupChange}
            handleNextStep={handleNextStep}
            onClose={onClose}
          />
        )}
        {step === 2 && (
          <AddTypeUser
            selectedType={selectedType}
            handleTypeChange={handleTypeChange}
            handleNextStep={handleNextStep}
            handleBackStep={handleBackStep}
            setSelectedTypeTitle={setSelectedTypeTitle}
          />
        )}

        {step === 3 && (
          <AddCSV
            // handleFileUpload={handleFileUpload}
            handleBackStep={handleBackStep}
            handleNextStep={handleNextStep}
            csvData={csvData}
            handleDrop={handleDrop}
            file={file}
          />
        )}

        {step === 4 && (
          <UserList
            csvData={csvData}
            handleSaveRow={handleSaveRow}
            editingRow={editingRow}
            setEditingRow={setEditingRow}
            handleEditRow={handleEditRow}
            handleBackStep={handleBackStep}
            handleSubmit={handleSubmit}
            selectedTypeTitle={selectedTypeTitle}
          />
        )}
      </div>
    </Modal>
  );
};

export default UploadUsersModal;

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
};
