import { useEffect } from "react";
import OnboardingPage from "../../../compnents/pages/Onboarding";

const Onboarding = ({ update }) => {
  useEffect(() => {
    document.title = `Onboarding `;
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main" tabIndex={0}>
      <OnboardingPage update={update} />
    </main>
  );
};

export default Onboarding;
