import React from "react";
import { TextField } from "@mui/material";
import "./style.css";

type InputProps = {
  id: string;
  label: string;
  name: string;
  onChange?: (value: string) => void;
  required: boolean;
  style?: object;
  value: string;
  variant?: "standard" | "outlined";
  type?: string;
  error: boolean;
  helperText: string;
};

const Input = ({
  id = "",
  label = "",
  name = "",
  onChange,
  required = true,
  style = {},
  type = "",
  variant = "outlined",
  value = "",
  error,
  helperText,
}: InputProps) => {
  return (
    <div className="inputWrapper">
      <TextField
        required={required}
        fullWidth
        name={name}
        id={id}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        style={{ width: "100%", ...style }}
        label={label}
        type={type}
        variant={variant}
        error={error}
        helperText={helperText}
      />
    </div>
  );
};

export default Input;
