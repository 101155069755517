import React, { useState } from "react";
import { Box, Typography, Button, Tooltip, IconButton } from "@mui/material";
import { SketchPicker } from "react-color";
import CustomButton from "../../common/Button";
import { Info } from "@mui/icons-material";

const PrimaryColor = ({
  color,
  handleColorChange,
  font,
  handleBack,
  handleNext,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Typography variant="h6">
        Choose your primary color{" "}
        <Tooltip title="Choose Color accessibility friendly">
          <IconButton>
            <Info />
          </IconButton>
        </Tooltip>
      </Typography>

      {color ? (
        <Typography fontFamily={font} color={color} variant="h6">
          this is how the text will look like
        </Typography>
      ) : (
        ""
      )}
      <Box display={"flex"}>
        <Button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Box
            mt={2}
            mr={4}
            sx={{
              width: "100px",
              height: "50px",
              backgroundColor: color,
              borderRadius: 1,
            }}
          ></Box>
        </Button>
        {isOpen && (
          <SketchPicker color={color} onChangeComplete={handleColorChange} />
        )}
      </Box>
      <Box sx={{ marginTop: 4 }} display={"flex"} justifyContent={"end"}>
        <CustomButton
          onClick={handleBack}
          sx={{
            marginRight: 2,
          }}
          style={{}}
          backgroundColor="white"
          text="Back"
        />

        <CustomButton onClick={handleNext} text="Next" />
      </Box>
    </Box>
  );
};

export default PrimaryColor;
