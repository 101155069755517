import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getTopicsOnboardedOrganisation } from "../../../store/api/organisation";
import Button from "../../common/Button";

const Topics = ({ topics, setTopics, handleBack, handleSave }) => {
  const { data: topicList, isLoading } = useQuery({
    queryKey: ["topicsOnboard"],
    queryFn: () => getTopicsOnboardedOrganisation({ searchTerm: "" }),
  });

  // Create a filter to allow adding new topics
  const filter = createFilterOptions();

  // Handle topic selection
  const handleTopicChange = (event, newValues) => {
    // Map to extract just the titles, handling both existing and new topics
    const processedTopics = newValues.map(
      (value) => value.inputValue || value.title
    );
    setTopics(processedTopics);
  };

  // Select all topics
  const handleSelectAll = () => {
    if (topicList) {
      // If all are currently selected, deselect all
      if (topics.length === topicList.length) {
        setTopics([]);
      } else {
        // Otherwise, select all
        setTopics(topicList);
      }
    }
  };

  // Check if all topics are selected
  const allSelected = topicList && topics.length === topicList.length;

  return (
    <Box>
      <Typography variant="h6">Choose your topics</Typography>

      <Autocomplete
        multiple
        loading={isLoading}
        options={topicList?.map((topic) => ({ title: topic })) || []}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.title
        }
        isOptionEqualToValue={(option, value) =>
          option.title === (typeof value === "string" ? value : value.title)
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the option to create a new topic if it doesn't exist
          const { inputValue } = params;
          const isExisting = options.some(
            (option) => option.title.toLowerCase() === inputValue.toLowerCase()
          );

          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        value={topics.map((topic) => ({ title: topic }))}
        onChange={handleTopicChange}
        renderInput={(params) => (
          <TextField {...params} label="Topics" placeholder="Select topics" />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.title}
              label={option.title}
              {...getTagProps({ index })}
            />
          ))
        }
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
        }}
      >
        <Button
          onClick={handleSelectAll}
          text={allSelected ? "Deselect All" : "Select All"}
        />
      </Box>

      <Box sx={{ marginTop: 4 }} display="flex" justifyContent="end">
        <Button
          onClick={handleBack}
          sx={{
            marginRight: 2,
          }}
          backgroundColor="white"
          text="Back"
        />
        <Button onClick={handleSave} text="Submit" />
      </Box>
    </Box>
  );
};

export default Topics;
