import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ArticleCard from "../../../common/ArticleCard";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const FeaturedContent = ({ articles }) => {
  const scrollContainerRef = useRef(null);
  const { primaryColor } = useOrganisation();

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <section style={{ marginRight: 0, marginTop: 24 }}>
      <div
        style={{
          backgroundColor: "#000",
          padding: "24px",
          position: "relative",
        }}
      >
        <Typography
          variant="h5"
          style={{ color: primaryColor, fontWeight: "bold" }}
        >
          Featured Content
        </Typography>
        <IconButton
          onClick={scrollLeft}
          style={{
            position: "absolute",
            top: "50%",
            left: "0",
            transform: "translateY(-50%)",
            zIndex: 1,
            color: primaryColor,
            backgroundColor: "#000",
            borderRadius: "50%",
          }}
        >
          <ChevronLeft />
        </IconButton>

        {/* Scrollable Content */}
        <div
          ref={scrollContainerRef}
          style={{
            overflowX: "auto",
            display: "flex",
            gap: "16px",
            padding: "20px",
            scrollBehavior: "smooth",
          }}
        >
          {articles.slice(0, 8).map((article, index) => (
            <ArticleCard
              article={article}
              key={index}
              width={280}
              featured={true}
            />
          ))}
        </div>

        {/* Right Arrow */}
        <IconButton
          onClick={scrollRight}
          style={{
            position: "absolute",
            top: "50%",
            right: "0",
            transform: "translateY(-50%)",
            zIndex: 1,
            color: primaryColor,
            backgroundColor: "#000",
            borderRadius: "50%",
          }}
        >
          <ChevronRight />
        </IconButton>
      </div>
    </section>
  );
};

export default FeaturedContent;
