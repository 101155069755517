import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckAuth } from "../../hooks/auth";
import Loading from "../../compnents/common/Loading";

const AuthRedirect = ({ element }) => {
  const navigate = useNavigate();
  const { refetch, data: isAuthenticated, isLoading } = useCheckAuth();

  useEffect(() => {
    refetch().then((res) => {
      if (res.data) {
        navigate("/dashboard", { replace: true });
      }
    });
  }, [refetch, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return element;
};

export default AuthRedirect;
