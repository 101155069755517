import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomSwitch from "../../../common/Swicth";
import Toast from "../../../common/Toast";

import {
  getUserNotification,
  updateUserNotification,
} from "../../../../store/api/users";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const NotificationSettings = () => {
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();

  const [emailFrequency, setEmailFrequency] = useState("0");
  const [pushEnabled, setPushEnabled] = useState(false);
  const [newsletterEnabled, setNewsletterEnabled] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [status, setStatus] = useState(200);

  const { data, isLoading } = useQuery({
    queryKey: ["userNotification"],
    queryFn: () => getUserNotification(),
  });

  const { mutate, isPending } = useMutation({
    mutationFn: updateUserNotification,
    onSuccess: async (data) => {
      queryClient.refetchQueries({
        queryKey: ["userNotification"], // Use an object with queryKey
      });
      setResponseMessage("Notification Updated");
      setStatus(200);
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (!isLoading) {
      setEmailFrequency(
        data?.notifications?.find((option) => option?.isSelected)?.id || "0"
      );
      setPushEnabled(data?.browserAlert || false);
      setNewsletterEnabled(data?.newsLetter || false);
    }
  }, [isLoading, data]);

  const handleEmailFrequencyChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmailFrequency(event.target.value);
  };

  // Handle the Switch toggle event
  const handlePushToggle = () => {
    if (!pushEnabled) {
      // Request notification permission directly when the switch is turned on
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          setPushEnabled(true);
          console.log("Notifications enabled");
          // Optional: show a test notification here
          new Notification("Notifications have been activated!");
        } else {
          console.log("Notification permission denied");
          setPushEnabled(false); // Remain disabled if permission is denied
        }
      });
    } else {
      // If switch is turned off, disable notifications
      setPushEnabled(false);
      console.log("Notifications disabled");
    }
  };

  const handleNewsletterToggle = () => {
    setNewsletterEnabled((prev) => !prev);
  };
  const handleUpdateNotification = () => {
    mutate({
      browserAlert: pushEnabled,
      newsLetter: newsletterEnabled,
      emailNotification: +emailFrequency,
    });
  };

  return (
    <Box sx={{ p: 4, mx: "auto" }}>
      <Typography variant="subtitle1" sx={{ mb: 3 }}>
        How do you want your notifications?
      </Typography>

      {/* Form Layout */}
      <Grid container spacing={4}>
        {/* By Email */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            By Email
          </Typography>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Weekly summary</InputLabel>
            <Select
              value={emailFrequency}
              onChange={handleEmailFrequencyChange}
            >
              {data?.notifications.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Weekly Newsletter */}
        {/* Browser Alert */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Browser alert
          </Typography>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={pushEnabled}
                onChange={handlePushToggle}
                name="primary"
                primaryColor={primaryColor || "#96f7dc"}
              />
            }
            label={
              <>
                Enable push messaging on this device{" "}
                <Tooltip title="Push messaging allows you to receive alerts directly in your browser.">
                  <IconButton size="small">
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            }
            labelPlacement="start" // Label will be after the CustomSwitch
          />
        </Grid>

        {/* Weekly Newsletter */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Weekly newsletter
          </Typography>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={newsletterEnabled}
                onChange={handleNewsletterToggle}
                name="time"
                primaryColor={primaryColor || "#96f7dc"}
              />
            }
            label={
              <>
                Receive a weekly curated newsletter{" "}
                <Tooltip title="Receive an email newsletter with curated content every week.">
                  <IconButton size="small">
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            }
            labelPlacement="start" // Label will be after the switch
          />
        </Grid>
      </Grid>

      {/* Save and Back Buttons */}
      <Divider sx={{ mt: 4, mb: 3 }} />
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <Button
          variant="outlined"
          sx={{
            color: "black",
            borderColor: "black",
            "&:hover": {
              borderColor: "black",
            },
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: primaryColor,
            color: "black",
            "&:hover": {
              backgroundColor: "#A7DFD0",
            },
          }}
          onClick={handleUpdateNotification}
        >
          Save
        </Button>
      </Box>
      <Toast message={responseMessage} status={status} />
    </Box>
  );
};

export default NotificationSettings;
