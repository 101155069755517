import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  useMediaQuery,
  Chip,
  Container,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";

import ShareArticle from "./ShareArticle";
import Loading from "../../../common/Loading";
import { getArticleByid } from "../../../../store/api/content";
import { readContentAnalytics } from "../../../../store/api/analytics";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import Button from "../../../common/Button";
import { getTextColor } from "../../../../helpers";

const ArticlePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { primaryColor, primaryColorDark, primaryColorLight } =
    useOrganisation();
  const textColor = getTextColor(primaryColor) || "black";

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const { data, isLoading } = useQuery({
    queryKey: ["article", id],
    queryFn: () =>
      getArticleByid({
        id,
      }),
    enabled: !!id,
  });

  const { data: analytics } = useQuery({
    queryKey: ["contentAnalytics", id],
    queryFn: () => readContentAnalytics({ contentId: id }),
  });

  return isLoading ? (
    <Loading />
  ) : (
    <Container maxWidth="lg" className="sectionCenter">
      <Box sx={{ padding: isSmallScreen ? 2 : 5 }}>
        <Grid container spacing={isSmallScreen ? 2 : 5}>
          <Grid container item xs={12} md={9} spacing={1} alignItems={"start"}>
            <Grid item xs={12} md={10}>
              <Typography variant="h4">{data?.title}</Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={{ marginBottom: 2 }}
              >
                {formatDistanceToNow(data?.dateCreated, {
                  addSuffix: true,
                })}
              </Typography>
            </Grid>
            <Grid container xs={12} md={12} display="flex" alignItems="start">
              <Grid item xs={12} md={6}>
                <CardMedia
                  component="img"
                  // height="300"
                  image={`${
                    data?.mainImage
                  }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
                  alt="Article Image"
                />
                <Box sx={{ marginTop: 2 }}>
                  {data?.topic
                    .split(",")
                    .slice(0, 3)
                    .map((el, i) => (
                      <Chip
                        key={i}
                        label={el}
                        style={{
                          marginRight: 4,
                          marginBottom: 4,
                          color: "black",
                          background: "white",
                          border: `1px solid ${primaryColor}`,
                        }}
                      />
                    ))}
                </Box>
                <Grid item md={6} xs={6}>
                  <ShareArticle />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems={"start"}
                padding={2}
              >
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  <Box
                    sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: data?.text }} />
                  </Box>
                </Typography>
                <Button
                  variant="contained"
                  text="View Article Detail"
                  to={data?.url}
                  linkExternal={true}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: 2 }}
              style={{ alignSelf: "start" }}
            >
              Featured
            </Typography>
            <Box>
              {data.featuredContents.map((item, index) => (
                <Card
                  key={index}
                  sx={{ marginBottom: 2 }}
                  onClick={() => navigate(`/article/details/${item.id}`)}
                  style={{ borderRadius: 0, boxShadow: "none", width: "300px" }}
                >
                  <CardMedia
                    component="img"
                    height="120"
                    image={`${
                      item?.mainImage
                    }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
                    alt={`Featured ${index}`}
                  />
                  <CardContent style={{ background: primaryColorLight }}>
                    <Typography variant="subtitle1">{item.title}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {data?.dateCreated &&
                        formatDistanceToNow(data?.dateCreated, {
                          addSuffix: true,
                        })}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 5 }}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            You may be interested in?
          </Typography>
          <Grid container spacing={isSmallScreen ? 2 : 5}>
            {data.relatedContents.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  style={{
                    cursor: "pointer",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 0,
                    height: "300px", // Fixed height for consistency
                  }}
                  onClick={() => navigate(`/article/details/${item?.id}`)}
                >
                  <CardMedia
                    component="img"
                    style={{
                      height: "120px",
                      objectFit: "cover",
                    }}
                    image={`${
                      item?.mainImage
                    }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
                    alt="Article Image"
                  />
                  <CardContent
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between", // Distribute content evenly
                      height: "130px", // Remaining space after image
                    }}
                  >
                    <Typography
                      variant="overline"
                      color="textSecondary"
                      style={{
                        color: primaryColor,
                        fontWeight: "bold",
                        fontSize: 10,
                      }}
                    >
                      {item?.topics.split(",").slice(0, 3).join(", ")}
                    </Typography>
                    <Typography
                      variant="h5"
                      style={{
                        marginBottom: "auto",
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <Box>
                      <Typography variant="caption" color="textSecondary">
                        {item?.scheduledDate &&
                          formatDistanceToNow(new Date(item?.scheduledDate), {
                            addSuffix: true,
                          })}
                      </Typography>
                      <hr
                        style={{
                          border: 0,
                          borderTop: "1px solid #ccc",
                          margin: "8px 0",
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ArticlePage;
