import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Toast from "../../common/Toast";
import Button from "../../common/Button";
import { getUserDetails, updateUserDetails } from "../../../store/api/users";

const AccountSettings = () => {
  const queryClient = useQueryClient();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [timezone, setTimezone] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [status, setStatus] = useState(200);

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    country: "",
    region: "",
    timezone: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { data, isLoading } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUserDetails(),
  });

  useEffect(() => {
    if (!isLoading && data) {
      setFullName(data.fullName || "");
      setEmail(data.email || "");
      setCountry(data.countries.find((el) => el.isSelected)?.id || "");
      setRegion(data.regions.find((el) => el.isSelected)?.id || "");
      setTimezone(data.timezones.find((el) => el.isSelected)?.id || "");
    }
  }, [isLoading, data]);

  const { mutate, isPending } = useMutation({
    mutationFn: updateUserDetails,
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: ["userDetails"] });
      setResponseMessage("Details Updated");
      setStatus(200);
    },
    onError: () => {
      setResponseMessage("Error occurred");
      setStatus(400);
    },
  });

  const validate = () => {
    const newErrors: any = {};
    if (!fullName) newErrors.fullName = "Full name is required.";
    if (!email) newErrors.email = "Email is required.";
    if (!country) newErrors.country = "Country is required.";
    if (!region) newErrors.region = "Region is required.";
    if (!timezone) newErrors.timezone = "Timezone is required.";
    if (oldPassword || newPassword || confirmPassword) {
      if (!oldPassword) newErrors.oldPassword = "old password is required.";
      if (!newPassword) newErrors.newPassword = "New password is required.";
      if (!confirmPassword)
        newErrors.confirmPassword = "Confirm password is required.";
      if (newPassword !== confirmPassword)
        newErrors.confirmPassword = "Passwords do not match.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (!validate()) return;

    const updatedData = oldPassword
      ? {
          fullName,
          email,
          locationId: country,
          regionId: region,
          timezoneId: timezone,
          companyType,
          jobTitle,
          updatePasswordModel: {
            currentPassword: oldPassword || undefined,
            newPassword: newPassword || undefined,
            confirmNewPassword: newPassword || undefined,
          },
        }
      : {
          fullName,
          email,
          locationId: country,
          regionId: region,
          timezoneId: timezone,
          companyType,
          jobTitle,
        };

    mutate(updatedData);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <section className="sectionCenter">
      <Box sx={{ p: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          Your settings
        </Typography>
        <Divider sx={{ mb: 4 }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Account
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Full Name *"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email *"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!errors.email}
                  helperText={errors.email}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors.country}
                >
                  <InputLabel>Country *</InputLabel>
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    label="Country *"
                  >
                    {data?.countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.country && (
                    <Typography color="error">{errors.country}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors.region}
                >
                  <InputLabel>Region *</InputLabel>
                  <Select
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    label="Region *"
                  >
                    {data?.regions.map((region) => (
                      <MenuItem key={region.id} value={region.id}>
                        {region.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.region && (
                    <Typography color="error">{errors.region}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors.timezone}
                >
                  <InputLabel>Timezone *</InputLabel>
                  <Select
                    value={timezone}
                    onChange={(e) => setTimezone(e.target.value)}
                    label="Timezone *"
                  >
                    {data?.timezones.map((el) => (
                      <MenuItem key={el.id} value={el.id}>
                        {el.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.timezone && (
                    <Typography color="error">{errors.timezone}</Typography>
                  )}
                </FormControl>
              </Grid>
              {/* Company Type */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Company Type"
                  value={companyType}
                  onChange={(e) => setCompanyType(e.target.value)}
                  variant="outlined"
                />
              </Grid>

              {/* Job Title */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Job Title"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Update password
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Old password"
                  type={showPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="New password"
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm password"
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box mt={4} display="flex">
              <Button text=" Back" backgroundColor="white" />
              <Button text="Save" onClick={handleSave} />
            </Box>
          </Grid>
        </Grid>
        <Toast message={responseMessage} status={status} />
      </Box>
    </section>
  );
};

export default AccountSettings;
