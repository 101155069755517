import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// -----------------------------------------------------
// Define types for GET list of ads for admin

interface adsListResponse {}
/**
 * @method GET
 * @route
 * @returns {Promise<adsListResponse>} returns a Promise either an error or the response object
 */
export const getAllAds = async (): Promise<adsListResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<adsListResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_GET_LIST_ADS}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET content list of ads for admin

interface adsContentListResponse {
  types: [];
  orders: [];
}
interface getAdminContentAdsParams {
  search: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<adsContentListResponse>} returns a Promise either an error or the response object
 */
export const getAllContentAds = async ({
  search,
}: getAdminContentAdsParams): Promise<adsContentListResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<adsContentListResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_GET_SEARCH_ADS_CONTENT}?searchTerm=${search}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET content list of ads for admin

interface addAdsTemplateResponse {}
interface getAdminAddAdsParams {}
/**
 * @method GET
 * @route
 * @returns {Promise<addAdsTemplateResponse>} returns a Promise either an error or the response object
 */
export const getAddAdsTemplate =
  async ({}: getAdminAddAdsParams): Promise<addAdsTemplateResponse> => {
    const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

    const response: AxiosResponse<addAdsTemplateResponse> = await axios.get(
      `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_GET_CREATE_TEMPLATE_ADS}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };

// -----------------------------------------------------
// Define types for GET content list of ads for admin

interface updateAdsTemplateResponse {}
interface getAdminUpdateAdsParams {
  id: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<updateAdsTemplateResponse>} returns a Promise either an error or the response object
 */
export const getUpdateAdsTemplate = async ({
  id,
}: getAdminUpdateAdsParams): Promise<updateAdsTemplateResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<updateAdsTemplateResponse> = await axios.get(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_GET_UPDATE_TEMPLATE_ADS}/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET content list of ads for admin

interface updateAdsResponse {}
interface UpdateAdsParams {
  id: string;
  order: number;
  contentIds: [];
  type: number;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<updateAdsResponse>} returns a Promise either an error or the response object
 */
export const updateAds = async ({
  id,
  order,
  contentIds,
  type,
}: UpdateAdsParams): Promise<updateAdsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<updateAdsResponse> = await axios.put(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_UPDATE_ADS}/${id}`,
    {
      order,
      contentIds,
      type,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for post ads for admin

interface updateAdsResponse {}
interface addAdsParams {
  order: number;
  contentIds: [];
  type: number;
}
/**
 * @method POST
 * @route
 * @returns {Promise<updateAdsResponse>} returns a Promise either an error or the response object
 */
export const addAds = async ({
  order,
  contentIds,
  type,
}: addAdsParams): Promise<updateAdsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<updateAdsResponse> = await axios.post(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_POST_ADS}`,
    {
      order,
      contentIds,
      type,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for post ads for admin

interface deleteAdsResponse {}
interface deleteAdsParams {
  id: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<deleteAdsResponse>} returns a Promise either an error or the response object
 */
export const deleteAds = async ({
  id,
}: deleteAdsParams): Promise<deleteAdsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<deleteAdsResponse> = await axios.delete(
    `${URL.CONTENT.BASE}${URL.CONTENT.V1_ADMIN_DELETE_ADS}/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
