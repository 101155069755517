import React from "react";
import { Button, Select, MenuItem, Typography } from "@mui/material";

const AddGroup = ({
  selectedGroup,
  handleGroupChange,
  handleNextStep,
  onClose,
}) => {
  return (
    <div>
      {/* <h2>Upload CSV</h2> */}
      <Typography variant="body1">
        Are you adding to a new or existing group?
      </Typography>
      <Select
        value={selectedGroup}
        onChange={handleGroupChange}
        displayEmpty
        fullWidth
      >
        <MenuItem value="" disabled>
          Select group
        </MenuItem>
        <MenuItem value="Group 1">Group 1</MenuItem>
        <MenuItem value="Group 2">Group 2</MenuItem>
      </Select>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <Button
          variant="contained"
          onClick={handleNextStep}
          disabled={!selectedGroup}
          style={{
            marginBottom: 8,
            background: selectedGroup ? "black" : "gray",
            color: "white",
            cursor: selectedGroup ? "pointer" : "notAllowed",
          }}
        >
          Upload to this group
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          style={{ color: "black", border: "1px solid black" }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddGroup;
