export const routes = {
  DASHBORD: "/dashbord",
};
export const roles = {
  Admin: "Admin",
  Reader: "Reader",
  Editor: "Editor",
  ContentCreator: "ContentCreator",
  SuperAdmin: "SuperAdmin",
};
