import { Box, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <footer style={{ position: "absolute", bottom: 0 }}>
      <Box
        sx={{
          //   marginTop: "auto",
          //   textAlign: "center",
          mt: 4,
        }}
      >
        <Typography variant="h6">
          Powered by <b>Komz</b>
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
