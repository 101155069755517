import React, { useEffect, useState } from "react";
import Table from "../../common/Table";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  approveContent,
  deleteContent,
  getAllAdminArticles,
  rejectContent,
} from "../../../store/api/content";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useNavigate } from "react-router-dom";
import { columns } from "./columnsData";
import StatusModal from "../../common/Modal";
import Toast from "../../common/Toast";
import PreviewContent from "./PreviewContent";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { getRole } from "../../../helpers/auth";
import { getTextColor } from "../../../helpers";

const AllContentPage = ({ approved, schedule }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const role = getRole();
  const { primaryColor } = useOrganisation();
  const textColor = getTextColor(primaryColor) || "black";

  const [searchTerm, setSearchTerm] = useState("");
  const [allContent, setAllContent] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("DateCreated");
  const [isDescending, setIsDescending] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [contentId, setContentId] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState({ message: false });
  const [responseMessage, setResponseMessage] = useState("");
  const [status, setStatus] = useState(200);
  const [openView, setOpenView] = useState(false);
  const [contentPreview, setContentPreview] = useState({});
  const [openArchive, setOpenArchive] = useState(false);
  const [statusContent, setStatusContent] = useState(null);
  const [SchedulDate, setSchedulDate] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: [
      "adminArticles",
      searchTerm,
      currentPage,
      sortBy,
      isDescending,
      statusContent,
      approved,
      SchedulDate,
    ],
    queryFn: () =>
      getAllAdminArticles({
        searchTerm,
        currentPage,
        sortBy,
        isDescending,
        status: statusContent,
        SchedulDate,
      }),
  });

  const { mutate: rejectMutate } = useMutation({
    mutationFn: () => rejectContent({ contentId }),
    onSuccess: (data) => {
      setMessage("");

      setStatus(200);
      setResponseMessage("Content Rejected");
      queryClient.refetchQueries({
        queryKey: ["adminArticles"], // Use an object with queryKey
      });
    },
    onError: (error) => {},
  });

  const { mutate: approveMutate } = useMutation({
    mutationFn: () => approveContent({ contentId }),
    onSuccess: (data) => {
      setMessage("");
      setStatus(200);
      setResponseMessage("Content Approved");
      queryClient.refetchQueries({
        queryKey: ["adminArticles"], // Use an object with queryKey
      });
    },
    onError: (error) => {},
  });

  const { mutate: archiveMutate } = useMutation({
    mutationFn: () => deleteContent([...selectedIds]),
    onSuccess: (data) => {
      setMessage("");
      setStatus(200);
      setResponseMessage("Content delete it");
      queryClient.refetchQueries({
        queryKey: ["adminArticles"], // Use an object with queryKey
      });
      setSelectedIds([]);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (!isLoading) {
      setAllContent(data?.paginatedContent);
      setTotalPage(data.totalPages);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (approved) {
      setStatusContent(0);
    } else if (schedule) {
      setSchedulDate(true);
    } else {
      setStatusContent(null);
    }
  }, [approved]);

  const handleCloseView = () => setOpenView(false);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchItem(term);
  };
  const handleItemSearch = () => {
    setSearchTerm(searchItem);
  };

  const handleStatusChange = (id, status) => {
    if (status === "Rejected" || status === "Approved") {
      setModalOpen(true);
    }
    setSelectedStatus(status);
    setContentId(id);
  };

  const handleApproveORReject = () => {
    if (selectedStatus === "Rejected") {
      if (message) {
        rejectMutate();
        setModalOpen(false);
        setError({ ...error, message: false });
      } else {
        setError({ ...error, message: true });
      }
    } else if (selectedStatus === "Approved") {
      approveMutate();
      setModalOpen(false);
    }
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  const handleSelect = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };
  const handleSelectAll = () => {
    if (selectedIds.length === allContent.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(allContent.map((article) => article.id));
    }
  };
  const handleArchive = () => {
    archiveMutate();
    setOpenArchive(false);
  };
  const handleClickOpen = () => {
    setOpenArchive(true);
  };

  const handleCloseDelete = () => {
    setOpenArchive(false);
  };
  return (
    <div>
      <Typography variant="h6" fontWeight={"bold"}>
        View all content
      </Typography>
      <Box>
        <Grid container flex={1} justifyContent={"space-between"}>
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <TextField
              variant="outlined"
              placeholder="Search"
              value={searchItem}
              sx={{ flexGrow: 1 }}
              onChange={handleSearch}
              style={{ border: "1px solid black", borderRadius: "8px" }}
            />
            <IconButton onClick={handleItemSearch}>
              <SearchIcon />
            </IconButton>
            <Button
              variant="raised"
              endIcon={<ControlPointIcon />}
              sx={{ backgroundColor: primaryColor, color: textColor }}
              onClick={() => navigate("/create-content")}
            >
              Add content
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{ color: "#888" }}
              onClick={handleClickOpen}
              disabled={selectedIds.length === 0}
            >
              Delete content
            </Button>
          </Box>
        </Grid>
      </Box>
      <Dialog
        open={openArchive}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete the selected items?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting these items cannot be undone. Please confirm if you want to
            proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleArchive} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Table
        data={allContent}
        columns={columns({
          selectedIds,
          handleSelect,
          handleSelectAll,
          handleStatusChange,
          setSortBy,
          setIsDescending,
          isDescending,
          setOpenView,
          setContentPreview,
          approved,
          schedule,
          setStatusContent,
          statusContent,
          primaryColor,
          setCurrentPage,
          role,
        })}
        totalPage={totalPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      <StatusModal
        open={modalOpen}
        status={selectedStatus}
        handleClose={handleClose}
        setMessage={setMessage}
        message={message}
        handleApproveORReject={handleApproveORReject}
        error={error}
      />

      <Toast message={responseMessage} status={status} />
      <PreviewContent
        open={openView}
        handleClose={handleCloseView}
        id={contentPreview.id}
      />
    </div>
  );
};

export default AllContentPage;
