import React, { useEffect } from "react";
import DasboardPage from "../../compnents/pages/Dashboard";

const Dashboard = () => {
  useEffect(() => {
    document.title = `Dashboard`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main" tabIndex={0}>
      <DasboardPage />
    </main>
  );
};

export default Dashboard;
