import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ResetPasswordPage from "../../../compnents/pages/ForgetPassword/ResetPassword";

const ResetPassword = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const email = searchParams.get("email");
  const resetToken = searchParams.get("token");

  useEffect(() => {
    document.title = `Reset Password`;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (email && resetToken) {
      localStorage.clear();
      localStorage.setItem("email", JSON.stringify(email));
      localStorage.setItem("resetToken", JSON.stringify(resetToken));
      navigate("/reset");
    }
  }, [email, navigate, resetToken]);

  return (
    <main id="main" tabIndex={0}>
      <ResetPasswordPage />
    </main>
  );
};

export default ResetPassword;
