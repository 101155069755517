import { Box, Button, Checkbox, Typography } from "@mui/material";
import dayjs from "dayjs";
import { SwapVert as SwapVertIcon } from "@mui/icons-material";

export const columns = ({
  setSortBy,
  setIsDescending,
  isDescending,
  handleEditUser,
  handleSelectAll,
  selectedIds,
  handleSelect,
}) => [
  {
    accessorKey: "fullName",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsSomeRowsSelected().length === 10}
          onChange={() => handleSelectAll()}
          style={{ color: "white" }}
        />
        <Typography variant="body2">FullName</Typography>
        <div
          onClick={() => {
            setSortBy("fullName");
            setIsDescending(!isDescending);
          }}
        >
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Checkbox
          checked={selectedIds.includes(row.original.id)}
          onChange={() => handleSelect(row.original.id)}
        />
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.fullName}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "email",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("email");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Email</Typography>
        <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "locationId",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("location");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Location</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "dateCreated",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => setSortBy("DateCreated")}
      >
        <Typography variant="body2">Date Created</Typography>
        <SwapVertIcon
          onClick={() => {
            setIsDescending(!isDescending);
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {dayjs(row.original.dateCreated).format("DD/MM/YYYY")}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "userType",
    header: () => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("userType");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Status</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    // cell: ({ row }) => (
    //   <>
    //     <Select
    //       value={row.original.status}
    //       onChange={(event) =>
    //         handleStatusChange(row.original.id, event.target.value)
    //       }
    //       sx={{ minWidth: 120 }}
    //       style={{
    //         border: "2px solid",

    //         borderColor:
    //           row.original.status === "Pending"
    //             ? "orange"
    //             : row.original.status === "Approved"
    //             ? primaryColor
    //             : "red",
    //         borderRadius: "24px",
    //       }}
    //     >
    //       {row.original.status === "Pending" && (
    //         <MenuItem style={{ backgroundColor: "none" }} value="Pending">
    //           Pending
    //         </MenuItem>
    //       )}
    //       <MenuItem style={{ backgroundColor: "none" }} value="Approved">
    //         Approved
    //       </MenuItem>
    //       <MenuItem style={{ backgroundColor: "none" }} value="Rejected">
    //         Rejected
    //       </MenuItem>
    //       {row.original.status === "Archived" && (
    //         <MenuItem style={{ backgroundColor: "none" }} value="Archived">
    //           Archived
    //         </MenuItem>
    //       )}
    //     </Select>
    //   </>
    // ),
  },
  {
    id: "view",
    header: "",
    cell: ({ row }) => (
      <Button
        variant="raised"
        onClick={() => {
          handleEditUser(row.original.id);
        }}
      >
        Edit
      </Button>
    ),
  },
];
