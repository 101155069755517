import { useQuery } from "@tanstack/react-query";

import { getOnboardedOrganisation } from "../store/api/organisation";
import { getRole } from "../helpers/auth";
import { roles } from "../constants/routes";
import { useEffect } from "react";
import Loading from "../compnents/common/Loading";

const COLORS = {
  primary: {
    light: "#e3fdf6",
    dark: "#70dbc4",
    normal: "#96f7dc",
  },
};

const adjustColorShade = (color, percent) => {
  let colorHex = color.replace("#", "");

  if (colorHex.length === 3) {
    colorHex = colorHex
      .split("")
      .map((hex) => hex + hex)
      .join(""); // Expands shorthand hex (e.g. "#abc" to "#aabbcc")
  }

  const r = parseInt(colorHex.substring(0, 2), 16);
  const g = parseInt(colorHex.substring(2, 4), 16);
  const b = parseInt(colorHex.substring(4, 6), 16);

  const adjust = (value) =>
    Math.round(Math.min(255, Math.max(0, value + (value * percent) / 100)));

  const newR = adjust(r).toString(16).padStart(2, "0");
  const newG = adjust(g).toString(16).padStart(2, "0");
  const newB = adjust(b).toString(16).padStart(2, "0");

  return `#${newR}${newG}${newB}`;
};

export const useOrganisation = () => {
  const token = localStorage.getItem("token");
  const organisationId = localStorage.getItem("organisationId");
  const role = getRole();

  const { data, isLoading, isFetched, error } = useQuery({
    queryKey: ["onboardedOrganisation", token],
    queryFn: () => getOnboardedOrganisation({}),
    enabled: !!token && role !== roles.SuperAdmin,
    staleTime: Infinity,
  });

  if (data) {
    const { isOnBoarded, primaryColor, primaryFont, logoLink } = data;
    const primaryColorLight = adjustColorShade(
      primaryColor || COLORS.primary.normal,
      20
    );
    const primaryColorDark = adjustColorShade(
      primaryColor || COLORS.primary.normal,
      0
    );

    return {
      isOnBoarded,
      isFetched,
      primaryColor,
      primaryFont,
      logoLink,
      primaryColorLight,
      primaryColorDark,
      organisationId,
    };
  }
  return {
    primaryColor: COLORS.primary.normal,
    primaryColorLight: COLORS.primary.light,
    primaryColorDark: COLORS.primary.dark,
    primaryFont: "Roboto",
  };
};
