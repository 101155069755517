import React, { useEffect } from "react";
import AllContentPage from "../../../compnents/pages/AllContent";

const AllContent = () => {
  useEffect(() => {
    document.title = `AllContent`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main" tabIndex={0}>
      <section className="sectionCenter">
        <h1 style={{ color: "gray" }}>Dashboard</h1>
        <hr />
        <AllContentPage approved={false} schedule={false} />
      </section>
    </main>
  );
};

export default AllContent;
