import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { ControlPoint, Delete } from "@mui/icons-material";
import Button from "../../../common/Button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addAds,
  getAddAdsTemplate,
  getAllContentAds,
  getUpdateAdsTemplate,
  updateAds,
} from "../../../../store/api/ads";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const AdFormModal = ({ handleClose, handleOpen, open, isEdit, editData }) => {
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();

  const [type, setType] = useState("");
  const [content, setContent] = useState([]);
  const [searchContent, setSearchContent] = useState("");

  const { data: contentData } = useQuery({
    queryKey: ["contentAds", searchContent],
    queryFn: () => getAllContentAds({ search: searchContent }),
  });
  const { data: addsTemplate } = useQuery({
    queryKey: ["adsTemplate"],
    queryFn: () => getAddAdsTemplate({}),
  });
  const { data: editadsTemplate, isLoading } = useQuery({
    queryKey: ["editAdsTemplate", editData],
    queryFn: () => getUpdateAdsTemplate({ id: editData }),
    enabled: isEdit,
  });

  const { mutate: addMutate } = useMutation({
    mutationFn: (ads) => addAds(ads),
    onSuccess: (data) => {
      queryClient.refetchQueries("adsList");
      handleClose();
      // setStatus(200);
      // setResponseMessage("Added successfullly");
    },
    onError: (error) => {
      // setStatus(400);
      // setResponseMessage("error accured");
    },
  });
  const { mutate: editMutate } = useMutation({
    mutationFn: (data) => updateAds(data),
    onSuccess: (data) => {
      queryClient.refetchQueries("adsList");
      handleClose();

      // setStatus(200);
      // setResponseMessage("Added successfullly");
    },
    onError: (error) => {
      // setStatus(400);
      // setResponseMessage("error accured");
    },
  });

  useEffect(() => {
    if (isEdit && !isLoading) {
      setType(editadsTemplate.types.find((el) => el.isSelected).id);
      setContent(editadsTemplate.contents);
    }
  }, [isEdit]);

  const handleDelete = (id) => {
    setContent(content.filter((item) => item.id !== id));
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedContent = Array.from(content);
    const [reorderedItem] = updatedContent.splice(result.source.index, 1);
    updatedContent.splice(result.destination.index, 0, reorderedItem);
    setContent(updatedContent);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      editMutate({
        id: editData,
        type: +type,
        contentIds: content.map((el) => el.id),
        order: editadsTemplate.types.find((el) => el.isSelected).id,
      });
    } else {
      addMutate({
        type,
        contentIds: content.map((el) => el.id),
        order: addsTemplate.orders[addsTemplate.orders.length - 1].id,
      });
    }
  };
  return (
    <>
      <Grid xs={9} md={3}>
        <Button
          variant="raised"
          endIcon={<ControlPoint />}
          sx={{ backgroundColor: primaryColor, color: "#333" }}
          onClick={handleOpen}
          text="Add Ads"
        />
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalResponsiveStyle}>
          <Typography variant="h6" component="h2" gutterBottom>
            Create New Ad
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Select Position</InputLabel>
                  <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    label="Set Position"
                  >
                    {addsTemplate?.types.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Select List Of Content</InputLabel>

                <Autocomplete
                  options={contentData || []}
                  getOptionLabel={(option) => option.title || ""}
                  onChange={(event, newValue) => {
                    if (
                      newValue &&
                      !content.some((item) => item.id === newValue.id)
                    ) {
                      setContent([...content, newValue]);
                      setSearchContent(""); // Clear input field after selection
                    }
                  }}
                  inputValue={searchContent}
                  onInputChange={(e, newInputValue) =>
                    setSearchContent(newInputValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Article"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="articles">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {content.map((article, index) => (
                          <Draggable
                            key={article.id}
                            draggableId={article.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 1,
                                  margin: "8px 0",
                                  backgroundColor: snapshot.isDragging
                                    ? "#f0f0f0"
                                    : "#fafafa",
                                  border: "1px solid #ddd",
                                }}
                              >
                                <Typography sx={{ flexGrow: 1 }}>
                                  {article.title}
                                </Typography>
                                <IconButton
                                  onClick={() => handleDelete(article.id)}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>

              <Grid item xs={12}>
                <Button text="Create Ad" type="submit" onClick={handleSubmit} />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const modalResponsiveStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

export default AdFormModal;
