import React, { useState } from "react";
import { TextField, FormControlLabel, Box, Typography } from "@mui/material";
import { format } from "date-fns";

import CustomSwitch from "../Swicth";
import { useOrganisation } from "../../../hooks/useOrganisation";

type DateProps = {
  scheduledDate: Date;
  setScheduledDate: Function;
  endDate: Date;
  setEndDate: Function;
};

const PublishDateSelector = ({
  scheduledDate,
  setScheduledDate,
  endDate,
  setEndDate,
}: DateProps) => {
  const [useEndDate, setUseEndDate] = useState(false);
  const [error, setError] = useState("");
  const { primaryColor } = useOrganisation();
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseEndDate(event.target.checked);
    if (!event.target.checked) {
      setEndDate(null);
      setError(""); // Clear any previous errors
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;

    // Validate that endDate is greater than scheduledDate
    if (new Date(newEndDate) <= new Date(scheduledDate)) {
      setError("End date must be greater than the scheduled date.");
    } else {
      setError(""); // Clear the error if the condition is met
      setEndDate(newEndDate);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <TextField
        label="Schedule your content"
        type="datetime-local"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={
          scheduledDate
            ? format(new Date(scheduledDate), "yyyy-MM-dd'T'HH:mm")
            : ""
        }
        onChange={(e) => setScheduledDate(e.target.value)}
        required
      />
      <FormControlLabel
        control={
          <CustomSwitch
            checked={useEndDate}
            onChange={handleSwitchChange}
            primaryColor={primaryColor || "#96f7dc"}
          />
        }
        label="Use End Date"
      />
      {useEndDate && (
        <Box>
          <TextField
            label="Schedule end date"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={
              endDate ? format(new Date(endDate), "yyyy-MM-dd'T'HH:mm") : ""
            }
            onChange={handleEndDateChange}
            error={Boolean(error)}
            helperText={error}
            required
          />
        </Box>
      )}
    </Box>
  );
};

export default PublishDateSelector;
