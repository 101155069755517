import React, { useEffect } from "react";
import AllContentPage from "../../../compnents/pages/AllContent";

const AllApprovedContent = () => {
  useEffect(() => {
    document.title = `Approved Content`;
    window.scrollTo(0, 0);
  }, []);
  return (
    <main id="main" tabIndex={0}>
      <section className="sectionCenter">
        <h1 style={{ color: "gray" }}>Dashboard</h1>
        <hr />
        <AllContentPage approved={true} />
      </section>
    </main>
  );
};

export default AllApprovedContent;
