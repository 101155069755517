import { Box, Typography } from "@mui/material";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";

export const columns = ({}) => [
  {
    accessorKey: "readTime",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2">Read Time</Typography>
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.readTime}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "title",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2">title</Typography>
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.title}
        </Box>
      </>
    ),
  },

  {
    accessorKey: "views",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        // onClick={() => {
        //   setSortBy("status");
        //   setIsDescending(!isDescending);
        // }}
      >
        <Typography variant="body2">views</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.views}
        </Box>
      </>
    ),
  },
];
